/* eslint-disable react/destructuring-assignment */
import { MenuProps, Modal, Space, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import UnsavedChangesContext from '../../context/UnsavedChanges';
import LOCALIZATION from '../../localization';
import AccessDeniedContext from '../../context/AccessDenied';
import NavigationContext from '../../context/NavigationContext';
import { NavbarButtonIconProps } from '../../pages/FlowTasks/types';

const StyledMenu = styled.ul`
  position: fixed;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  background-color: var(--color-primary-01);
  color: var(--color-neutral-04);
  margin: 0;
  padding: 0;
  top: 100px;
  width: 80px;

  & li.custom-menu-item {
    width: 100%;
    cursor: pointer;
    transition: border-color 0.3s,background 0.3s;
    min-height: 93px;
    align-items: center;
    display: flex;

    &.custom-menu-item-logout {
      border-top: 1px solid var(--color-neutral-01);
      position: fixed;
      bottom: 0;
      min-height: 90px;
      line-height: 80px;
      width: 80px;

      & button {

        & div {
          margin-bottom: 0px;
        }
      }

      & .custom-menu-title-content {
        display: none;
      }
    }

    &:hover,
    &.custom-menu-item-selected {
      color: var(--color-primary-02);
      background-color: var(--color-primary-02);

      & button {
        background-color: var(--color-primary-02);
        & div {
          background-color: var(--color-white);
        }
        & span.custom-menu-title-content {
          color: var(--color-white);
        }
      }
    }

    & button {
      height: 100%;
      width: 100%;
      margin: 16px 0;
      background-color: var(--color-primary-01);
      border: 0;
      cursor: pointer;
      transition: border-color 0.3s,background 0.3s;
  
      & span.custom-menu-title-content {
        color: var(--color-white);
        font-family: Interstate;
        font-size: 12px;
        font-weight: 100;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }  
    }
  }
`

const NavbarLogoIcon = styled('div')<any>`
  mask-image: url(/icons/SDGE_logo_new.svg);
  -webkit-mask-image: url(/icons/SDGE_logo_new.svg);
  width: 80px;
  height: 80px;
  display: block;
  margin-top: ${props => props.mt};
  mask-repeat: no-repeat;
  background-color: var(--color-white);
  color: var(--color-white);
  position: fixed;
`

const NavbarButtonIcon = styled('div')<NavbarButtonIconProps>`
  mask-image: url(/icons/${props => props.icon}.svg);
  -webkit-mask-image: url(/icons/${props => props.icon}.svg);
  width: 22px;
  height: 22px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  mask-repeat: no-repeat;
  background-color: var(--color-white);
`

const Env = styled('div')<any>`
  text-align: center;
  padding: 3px;
  background: ${props => props.bg};
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  position: fixed;
  z-index: 99999;
`

const items: MenuProps['items'] = [
  {
    label: 'Find Scans',
    key: 'find-scans'
  },
  {
    label: 'Define Forms',
    key: 'define-forms'
  },
  {
    label: 'Flow Tasks',
    key: 'flow-tasks'
  },
  {
    label: 'Logout',
    key: 'logout'
  }
];

const keyValueItems: Record<string, string> = {
  'find-scans': 'Find Scans',
  'define-forms': 'Define Forms',
  'flow-tasks': 'Flow Tasks'
}

/**
 * A component with look and logic for navigation bar in the top of the page.
 * 
 * Allows users to navigate through all available pages of application.
 * By visual and logic it is a clone of Antd's Menu component, but fixing issues with hanging hover effect.
 * 
 * Importing UnsavedChangesContext it provides modal window, if unsaved changes are present, with callbacks for Ok, Sign-Out and Cancel button defined in the current open page.
 * 
 * @returns {React.ReactElement} Fields table
 */
function Navbar() {
  const { unsavedChanges, markSavedChanges, trigger, triggerCallback, resetTrigger, modalTexts, cleanUpCallback, setTriggerCallback, changeTrigger, setTriggerModalTexts } = useContext(UnsavedChangesContext)
  const { updateCurrentPageTitle } = useContext(NavigationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname.substring(1);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [clickEventKey, setClickEventKey] = useState<string>('');
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([location.pathname.substring(1)])

  const { isAccessDenied, triggerAccessDeniedModal } = useContext(AccessDeniedContext);

  useEffect(() => {
    if(trigger !== 'null') setShowChangesModal(() => true);
  }, [trigger])

  useEffect(() => {
    setSelectedKeys(() => [location.pathname.substring(1)]);
  }, [location.pathname.substring(1)])

  const doSignOut = () => {
    Auth.signOut();
    cleanUpCallback();
  }

  const onClick = (e:any) => {
    if(isAccessDenied) triggerAccessDeniedModal();
    
    if (e.key === 'logout') {
      setSelectedKeys(() => [location.pathname.substring(1)])
      setTriggerCallback(doSignOut);
      setTriggerModalTexts(
        LOCALIZATION.SIGN_OUT_MODAL_TITLE,
        unsavedChanges ? LOCALIZATION.SIGN_OUT_MODAL_UNSAVED_CHANGES_MESSAGE : LOCALIZATION.SIGN_OUT_MODAL_SAVED_CHANGES_MESSAGE,
        LOCALIZATION.SIGN_OUT_MODAL_OK_BUTTON,
        LOCALIZATION.CANCEL_BUTTON_TITLE
      )
      changeTrigger();
    } else if(unsavedChanges) {
      setShowChangesModal(() => true);
      setClickEventKey(() => e.key);  
    } else {
      cleanUpCallback();
      setSelectedKeys(() => [e.key])
      updateCurrentPageTitle(e.label);
      navigate(`/${e.key}`);    
    }
  };

  const onModalChangesOk = () => {
    markSavedChanges();
    setShowChangesModal(() => false);
    if(triggerCallback && trigger !== 'null') {
      triggerCallback();
      resetTrigger();
    }
    else if(current === clickEventKey){
      cleanUpCallback();
      setSelectedKeys(() => [clickEventKey])
      updateCurrentPageTitle(keyValueItems[clickEventKey]);
      navigate(`/${clickEventKey}`);
    }
    else {
      setSelectedKeys(() => [clickEventKey])
      updateCurrentPageTitle(keyValueItems[clickEventKey]);
      navigate(`/${clickEventKey}`);
    }
  }

  const onModalChangesCancel = () => {
    setShowChangesModal(() => false);
    resetTrigger();
  }

  const getEnv = () => {
    let env = "";
    if (window.location.origin.includes("dev")) env = "DEV ENVIRONMENT";
    else if (window.location.origin.includes("qa")) env = "QA ENVIRONMENT";
    return env;
  }

  const getBgColour = () => {
    let bg = "";
    if (window.location.origin.includes("dev")) bg = "var(--color-green-01)";
    else if (window.location.origin.includes("qa")) bg = "var(--color-purple)";
    return bg;
  }
  
  // to do login preparation
  if (current === 'login') {
    return null;
  }

  return (
    <>
      {createPortal(
        <Modal
          centered
          title={modalTexts.title ? modalTexts.title : LOCALIZATION.DISCARD_CHANGES_MODAL_TITLE}
          open={showChangesModal}
          onOk={() => onModalChangesOk()}
          okText={modalTexts.okButton ? modalTexts.okButton : LOCALIZATION.DISCARD_CHANGES_MODAL_OK_BUTTON}
          onCancel={() => onModalChangesCancel()}
          cancelText={modalTexts.cancelButton ? modalTexts.cancelButton : LOCALIZATION.CANCEL_BUTTON_TITLE}
        >
          <Space style={{padding: '30px 0'}}>
            <Typography.Text>
              {modalTexts.message ? modalTexts.message : LOCALIZATION.DISCARD_CHANGES_MODAL_MESSAGE}
            </Typography.Text>
          </Space>
        </Modal>,
        document.getElementById('modals') as HTMLElement
      )}
      {getEnv() && <Env bg={getBgColour()}>{getEnv()}</Env>}
      <NavbarLogoIcon mt={getEnv() ? '20px' : '15px'} />
      <StyledMenu>
        {items?.map((item: any) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, no-nested-ternary
          <li key={item.key} className={selectedKeys.includes(item.key) ? 'custom-menu-item custom-menu-item-selected' : item.key === 'logout' ? 'custom-menu-item custom-menu-item-logout' : 'custom-menu-item'} onClick={() => onClick(item)}>
            <button type='button' onClick={() => onClick(item)}>
              <NavbarButtonIcon icon={item.label.charAt(0).toLocaleLowerCase() + item.label.slice(1).replace(' ','')}/>
                <span className='custom-menu-title-content'>
                  {item.key !== 'logout' ? item.label : ''}
                </span>
            </button>
          </li>
        ))}
      </StyledMenu>
    </>
  )
}

export default Navbar;
