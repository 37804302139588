import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { ConfigProvider } from 'antd';
import {Auth} from "aws-amplify";
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalCSS from './global.css';
import theme from './theme';
import AwsConfigAuth  from "./conf/cognito";
import { store } from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Auth.configure({ Auth: AwsConfigAuth });

Auth.currentAuthenticatedUser()
  .then(() => {
    root.render(
      <React.StrictMode>
          <GlobalCSS />
          <ConfigProvider theme={theme}>
            <Provider store={store}>
              <App />
            </Provider>
          </ConfigProvider>
      </React.StrictMode>
    );
  })
  .catch(() => {
    Auth.federatedSignIn({ customProvider: 'ActiveDirectory' })
  });

if(process.env.REACT_APP_NODE_ENV?.trim().toLowerCase() === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
