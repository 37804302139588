/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";
import type { InputRef } from 'antd';
import { useRef, useState } from "react";
import LOCALIZATION from '../../../localization';

/**
 * An inteface to describe label-value pair
 *
 * @interface SelectOption
 * @typedef {SelectOption}
 */
interface SelectOption {
    /**
     * @type {string}
     */
    value: string;
    /**
     * @type {string}
     */
    label: string;
}

/**
 * An interface to describe keys for props of this component
 *
 * @interface DropdownAddingNewTypeProps
 * @typedef {DropdownAddingNewTypeProps}
 */
interface DropdownAddingNewTypeProps {
    /**
     * A list with already existing form types to display
     * @type {*}
     */
    menu: any,
    /**
     * A reference to method from DefineForms, that will handle adding new form type
     * @type {(name: string) => void}
     */
    onAddNewType: (name: string) => void;
    /**
     * A reference to method from DefineForms, that will filter special characters from new form type's name
     * @type {(value: string) => string}
     */
    filterSpecialChars: (value: string) => string;
}


/**
 * DropdownAddingNewType is a custom component, that enables feature to add a new form type to form type dropdown in DefineForms.
 *
 * @param {DropdownAddingNewTypeProps} { menu, onAddNewType, filterSpecialChars }
 * @returns {*}
 */
function DropdownAddingNewType({ menu, onAddNewType, filterSpecialChars }: DropdownAddingNewTypeProps) {
    const inputRef = useRef<InputRef>(null);
    const [name, setName] = useState('');
    const [noInput, setNoInput] = useState(false);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(filterSpecialChars(event.target.value));
        // eslint-disable-next-line no-unneeded-ternary
        setNoInput(event.target.value? false : true);
    };

    const addNew = () => {
      if (!name) {
        setNoInput(true);
        return;
      }
      onAddNewType(name);
      setName("");
    }

    return (
        <>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <Space style={{ padding: '0 8px 4px' }}>
          <Input
            placeholder={LOCALIZATION.DEFINE_FORMS_NEW_TYPE_PLACEHOLDER}
            title={LOCALIZATION.DEFINE_FORMS_NEW_TYPE_PLACEHOLDER}
            ref={inputRef}
            value={name}
            onChange={onNameChange}
            style={{width: '180px'}}
            status={noInput ? "error" : ""}
            maxLength={20}
          />
          <Button type="text" icon={<PlusOutlined />} onClick={addNew}>
            {LOCALIZATION.DEFINE_FORMS_NEW_TYPE_SUBMIT}
          </Button>
        </Space>
      </>
    )
}


export default DropdownAddingNewType;