// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw'
import configTypes from "./data/config_types.json";

export default [
    rest.get('/mockedApi/configtypes/all', (req, res, ctx) => res(
        ctx.delay(1000),
        ctx.status(200),
        ctx.json({
            results: configTypes.datatypes
        })
    ))
]