/* eslint-disable eqeqeq */
import axios from "axios"
import { v4 as uuid } from 'uuid';
import { ApiResponse } from "../interfaces/ApiResponse";
import { Record} from '../interfaces/Record';

/**
 * An interface descriging a template object in response
 * @interface
 */
export interface TemplateResponse {
    /**
     * @type {string}
     */
    id: string;
    /**
     * @type {string}
     */
    templateName: string;
    /**
     * @type {string}
     */
    status: string;
    /**
     * @type {string}
     */
    lastUpdate: string;
    /**
     * @type {string}
     */
    createdAt: string;
    /**
     * @type {string}
     */
    flowName: string;
    /**
     * @type {string}
     */
    outputLocation: string;
    /**
     * @type {string}
     */
    outputSchema: string;
    /**
     * @type {boolean}
     */
    allForManualReview: boolean;
    /**
     * @type {string}
     */
    lastUsedDateTime: string;
}

/**
 * An interface describing a list of templates in response
 * @interface
 */
export interface TemplatesAllResponse {
    /**
     * @type {string}
     */
    formType: string;
    /**
     * @type {TemplateResponse[]}
     */
    templates: TemplateResponse[];
}


/**
 *  An interface describing type of template in response
 * 
 * @export
 * @interface TemplateTypeOutput
 * @typedef {TemplateTypeOutput}
 */
export interface TemplateTypeOutput {
    /**
     * @type {string}
     */
    type: string;
}

/**
 * An interface describing overall template object in response
 *
 * @export
 * @interface TemplateOutput
 * @typedef {TemplateOutput}
 */
export interface TemplateOutput {
    /**
     * @type {string}
     */
    id: string;
    /**
     * @type {string}
     */
    formName: string;
    /**
     * @type {string}
     */
    formType: string;
    /**
     * @type {string}
     */
    status: string;
    /**
     * @type {string}
     */
    lastUpdate: string;
    /**
     * @type {string}
     */
    createdAt: string;
    /**
     * @type {string}
     */
    flowName: string;
    /**
     * @type {string}
     */
    outputLocation: string;
    /**
     * @type {string}
     */
    outputSchema: string;
    /**
     * @type {boolean}
     */
    allForManualReview: boolean;
    /**
     * @type {string}
     */
    lastUsedDateTime: string;
}

/**
 *  An interface describing output in response with list of templates and type of form
 * 
 * @export
 * @interface TemplatesAllOutput
 * @typedef {TemplatesAllOutput}
 */
export interface TemplatesAllOutput {
    /**
     * @type {string}
     */
    type: string;
    /**
     * @type {TemplateOutput[]}
     */
    formTemplates: TemplateOutput[];
}

/**
 * @export
 * @interface UploadFormResponse
 * @typedef {UploadFormResponse}
 */
export interface UploadFormResponse {
    textractResults: { [key: string]: Array<Record> };
    /**
     * @type {Array<Record>}
     */
    // textractResults: Array<Record>;
    /**
     * @type {string}
     */
    imageName: string;
    /**
     * @type {string}
     */
    formUploadPresignedURL: string;
    /**
     * @type {?string}
     */
    message?: string;
} 

/**
 * @export
 * @interface AnalyzeFormResponse
 * @typedef {AnalyzeFormResponse}
 */
export interface AnalyzeFormResponse {
    textractResults: { [key: string]: Array<Record> };
    /**
     * @type {Array<Record>}
     */
    // textractResults: Array<Record>;
    /**
     * @type {string}
     */
    preSignImgUrl: string;
    /**
     * @type {string}
     */
    imageSrc: string;
    /**
     * @type {?string}
     */
    message?: string;
    status?: string;
    transactionId?: string;
}

/**
 * @export
 * @interface ImageSaveResponse
 * @typedef {ImageSaveResponse}
 */
export interface ImageSaveResponse {
    /**
     * @type {?string}
     */
    message?: string;
    /**
     * @type {string}
     */
    newImageSrc: string;
}

/**
 * An API service for retrieving and updating forms and templates
 * 
 * @module api/formsApi.service
 *
 * @type {{ getAll: () => any; putTemplateByName: (formType: string, templateName: string, payload: any) => any; uploadForm: (imageName: string, imageBase64: string) => any; analyzeForm: (formBase64: string, imageExt: string, pageNo: string) => any; saveImage: (formType: string, templateName: string, imageSrc: string) => any; }}
 */
export const formsApi = {
    /**
     * Fetch all templates and remap those to TemplatesAllOutput
     * 
     * @returns {Promise<ApiResponse>} A general API response
     */
    getAll: async (): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/templates/all`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    
            const alteredResponseData = { ...response.data};
            alteredResponseData.results = alteredResponseData.results.map((result: TemplatesAllResponse): TemplatesAllOutput => (
                {
                    type: result.formType,
                    formTemplates: result.templates.map((template: TemplateResponse): TemplateOutput => ({
                        id: uuid(),
                        formName: template.templateName,
                        formType: result.formType,
                        status: template.status,
                        lastUpdate: template.lastUpdate,
                        createdAt: template.createdAt,
                        flowName: template.flowName,
                        outputLocation: template.outputLocation,
                        outputSchema: template.outputSchema,
                        allForManualReview: template.allForManualReview,
                        lastUsedDateTime: template.lastUsedDateTime
                    }))
                }
            ))
            return alteredResponseData;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Save a new template or update existing one
     * 
     * @param {string} formType A form type 
     * @param {string} templateName A name of template
     * @param {any} payload An object with values
     * @returns {Promise<ApiResponse>} A general API response
     */
    putTemplateByName: async (formType: string, templateName: string, payload: any): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/template/byName/${formType}/${templateName}`;
            const response = await axios.put(path, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })    
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: payload,
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: payload,
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Upload a new form as PDF or IMAGE to get template fields
     * 
     * @param {string, null} imageName Name of image file
     * @param {string} imageBase64 An image in base64 
     * @returns {Promise<UploadFormResponse>} An API response with template fields
     */
    uploadForm: async (imageName: string | null, imageBase64: string | null): Promise<UploadFormResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/template/analyzeImage`;
            const response = await axios.post(path, {
                imageName,
                imageBase64,
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            return {
                ...response.data,
                message: null,
                responseMessage: response.data.message
            };
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    textractResults: {},
                    imageName: '',
                    formUploadPresignedURL: '',
                    message: error.message as string
                };    
            }
            
            return {
                textractResults: {},
                imageName: '',
                formUploadPresignedURL: '',
                message: error as string
            };   
        }
    },
    /**
     * Fetch template fields from Textract and presigned image URL after providing form in base64 
     * 
     * @param {string} formBase64 
     * @param {string} imageExt 
     * @param {string} pageNo  
     * @returns {Promise<AnalyzeFormResponse>} An API response with presigned url and template fields
     */
    analyzeForm: async (formBase64: string | null, imageExt: string, pageNo: string, transactionId?: string): Promise<AnalyzeFormResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/template/analyzeForm`;
            let reqObj: any = { formBase64, imageExt, pageNo };
            if (transactionId) reqObj = {...reqObj, transactionId};
            const response = await axios.post(path, reqObj, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    preSignImgUrl: '',
                    imageSrc: '',
                    textractResults: {},
                    message: error.message as string
                };    
            }
            
            return {
                preSignImgUrl: '',
                imageSrc: '',
                textractResults: {},
                message: error as string
            };   
        }
    },
    /**
     * 
     * @param {string} formType A form type
     * @param {string} templateName A template name
     * @param {string} imageSrc Path to image
     * @returns {Promise<ImageSaveResponse>} An API response with path to image
     */
    saveImage: async (formType: string, templateName: string, imageSrc: string): Promise<ImageSaveResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/template/saveImage`
            const response = await axios.post(path, {
                formType,
                templateName,
                imageSrc
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    newImageSrc : '',
                    message: error.message as string
                };    
            }
            
            return {
                newImageSrc: '',
                message: error as string
            };   
        }
    },
}