import {
  EditorPropTypes,
  MaskedRectangleProps,
  RectangleProps,
} from '../types';

import Editor from './Editor';
import MaskedRectangle from './MaskedRectangle';
import Rectangle from './Rectangle';

import RectangleSelector from './RectangleSelector';

const defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  onCancel: () => {},
  onBoxClick: () => {},
  type: RectangleSelector.TYPE,
  selector: RectangleSelector,
  disableAnnotation: false,
  activeAnnotationComparator: (a: any, b: any) => a === b,
  renderSelector: ({ annotation }: MaskedRectangleProps) => (
    <MaskedRectangle annotation={annotation} />
  ),
  renderEditor: ({ annotation, onSubmit, onCancel }: EditorPropTypes) => (
    <Editor annotation={annotation} onSubmit={onSubmit} onCancel={onCancel} />
  ),
  renderOutline: ({ key, annotation, active, hovered, onDelete, hideDeleteButton, hideNonIssues }: RectangleProps) => (
    <Rectangle
      key={key}
      annotation={annotation}
      active={active}
      hovered={hovered}
      onDelete={onDelete}
      hideDeleteButton={hideDeleteButton}
      hideNonIssues={hideNonIssues}
    />
  ),
};

export default defaultProps;
