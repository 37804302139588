/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from '../../interfaces/ApiStatus';
import configTypesApi, { ConfigType, ConfigTypesApiResponse } from '../../api/configTypesApi.service';

/**
 * An interface describing a state of configTypes store's slice
 *
 * @interface ConfigTypeState
 * @typedef {ConfigTypeState}
 */
interface ConfigTypeState {
    /**
     * @type {Array<ConfigType>}
     */
    list: Array<ConfigType>,
    /**
     * @type {ApiStatus}
     */
    status: ApiStatus,
    /**
     * @type {(string | null)}
     */
    error: string | null,
}
/**
 * Initial state of configTypes store
 *
 * @type {ConfigTypeState}
 */
const initialState: ConfigTypeState = {
    list: [],
    status: 'idle',
    error: null
}

/**
 * An async thunk to fetch all config types
 *
 * @type {*}
 */
export const fetchConfigTypes = createAsyncThunk('configTypes/fetchAll', async () => {
    const response: ConfigTypesApiResponse = await configTypesApi.getAll();
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }

    return response;
})


/**
 * A slice in configTypes store with reducers
 *
 * @type {*}
 */
export const configTypes = createSlice({
    name: 'configTypes',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(fetchConfigTypes.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchConfigTypes.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list = action.payload.results as ConfigType[];
            return state;
        })
        .addCase(fetchConfigTypes.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            return state;
        })
    }
})

/**
 * A helper method to select config type by name
 *
 * @param {ConfigTypeState} state A state of configTypes store
 * @param {string} name A name of config type
 * @returns {ConfigType} Returns found config type or the first config type in the list, which should be string
 */
export const selectConfigTypeByName = (state: ConfigTypeState, name: string) => {
    const foundObject = state.list.find((type: ConfigType) => type.name === name);
    if(!foundObject) {
        // throw new Error(`Type "${name}" was not found among known data types.`);
        console.warn(`Type "${name}" was not found among known data types.`);
        return state.list[0];
    } 
    return foundObject;
    
};

// export const { } = configTypes.actions;

export default configTypes.reducer