import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

interface PageHeaderProps {
    title: string;
    button: React.ReactNode | undefined;
    children: React.ReactNode;
}

function PageHeader({ title, button, children }: PageHeaderProps) {

    return (
        <div style={{width: '100%'}}>  
            <Row>
                <Col span={20} style={{padding: '0 48px'}}>
                    <h1 className='page-title' style={{fontSize: '24px', fontFamily:'Interstate', fontWeight: 'bold'}}>
                        {title}
                    </h1>
                </Col>
                <Col span={4} style={{ position: "relative", right: "3rem" }}>
                    {button}
                </Col>
            </Row>
            <Row>
                {children}
            </Row>
        </div>
    );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  button: PropTypes.element
};

PageHeader.defaultProps = {
  title: '',
  button: undefined
};

export default PageHeader;
