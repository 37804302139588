/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import { ApiStatus } from '../../interfaces/ApiStatus';
import outputsApi, { FetchOutputsResponseData } from '../../api/outputsApi.service';

/**
 * An interface describing state of outputs store
 *
 * @interface OutputState
 * @typedef {OutputState}
 */
interface OutputState {
    schema: string[],
    list: Array<FetchOutputsResponseData>,
    status: ApiStatus,
    error: string | null
}

/**
 * An initial state of outputs store
 *
 * @type {OutputState}
 */
const initialState: OutputState = {
    schema: [],
    list: [],
    status: 'idle',
    error: null
}

/**
 * An async thunk function to fetch outputs by schema name
 *
 * @type {*}
 */
export const fetchOutputBySchema = createAsyncThunk('outputs/bySchema', async (schema: string) => {
    const response = await outputsApi.getBySchema(schema);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    return response;
})

/**
 * An async thunk function to fetch all outputs
 *
 * @type {*}
 */
export const fetchOutputs = createAsyncThunk('output/fetchOutputs', async () => {
    const response = await outputsApi.getAll();
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    return response;
})

/**
 * A slice with reducers of outputs store
 *
 * @type {*}
 */
export const outputs = createSlice({
    name: 'outputs',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchOutputs.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchOutputs.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list = action.payload.results as FetchOutputsResponseData[];
            return state;
        })
        .addCase(fetchOutputs.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            return state;
        }).addCase(fetchOutputBySchema.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchOutputBySchema.fulfilled, (state, action) => {
            state.status = 'completed';
            state.schema = action.payload.results as string[];
            return state;
        })
        .addCase(fetchOutputBySchema.rejected, (state, action) => {
            state.status = 'failed';
            state.schema = [];
            state.error = action.error.message as string;
            return state;
        })
    }
})

export default outputs.reducer