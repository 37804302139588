export default {
    bgHeaderCol: '#EEEEEE',
    bgLayout: '#F5F5F5',
    bgButtonIdle: '#2296F2',
    bgButtonHover: '#c1e4ff',
    textButtonHover: '#f2f2f2',
    textNormal: '#7D7D7D',
    textDarker: '#4f4f4f',
    highlightHasIssues: '#F8F4D0',
    highlightSelectedRow: '#1677FF',
    unlockedLockColor: '#A6A7A1',
    lockedLockColor: '#6AA670',
    unlockedLockColorWithError: '#b90000',
    colorNeutral: '#757575'
}
