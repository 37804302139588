import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MaskedRectangleProps } from '../types';

const Box = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

/*
  Component for rendering selector for drawing new bounding box
*/

function MaskedRectangle({
  annotation,
  className,
  style,
}: MaskedRectangleProps) {
  const { geometry } = annotation;

  if (!geometry) return null;

  return (
    <Container className={className} style={style}>
      <Box
        style={{
          height: `${geometry.y}%`,
          width: '100%',
        }}
      />
      <Box
        style={{
          top: `${geometry.y}%`,
          height: `${geometry.height}%`,
          width: `${geometry.x}%`,
        }}
      />
      <Box
        style={{
          top: `${geometry.y}%`,
          left: `${geometry.x + geometry.width}%`,
          height: `${geometry.height}%`,
          width: `${100 - (geometry.x + geometry.width)}%`,
        }}
      />
      <Box
        style={{
          top: `${geometry.y + geometry.height}%`,
          height: `${100 - (geometry.y + geometry.height)}%`,
          width: '100%',
        }}
      />
    </Container>
  );
}

MaskedRectangle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  annotation: PropTypes.shape({
    geometry: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
};

MaskedRectangle.defaultProps = {
  className: '',
  style: {},
};

export default MaskedRectangle;
