import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RectangleProps } from '../types';

const Container = styled.div`
  // box-shadow: 0px 0px 1px 1px white inset;
  box-sizing: border-box;
  transition: box-shadow 0.21s ease-in-out;
`;

const Button = styled.div`
  background: whitesmoke;
  position: absolute;
  left: 100%;
  color: black;
  cursor: pointer;
  font-size: 0.5rem;
  font-weight: 900;
  padding: 2px 4px;
  text-align: center;
  &:focus,
  &:hover {
    background: whitesmoke;
  }
  z-index: 2;
  border: 0;
  box-sizing: border-box;
  margin-left: 2px;
  margin-top: -2px;
`;

/*
  Component for rendering existing(saved) bouding box
*/

function Rectangle({
  key,
  annotation,
  className,
  style,
  active,
  hovered,
  onDelete,
  hideDeleteButton,
  hideNonIssues
}: RectangleProps) {
  const { geometry } = annotation;
  if (!geometry) return null;

  const setBackgroundColor = (isActive: boolean, isHovered: boolean) => {
    if(isHovered) {
      return 'rgba(33, 150, 243, 0.25)';
    }

    if(annotation.isHighlighted && !annotation.hasIssue) {
      return 'rgba(33, 150, 243, 0.25)';
    }

    if(isActive && annotation.hasIssue) {
      return 'rgba(255, 235, 59, 0.25)'
    }

    if(isActive && !annotation.hasIssue) {
      return 'rgba(21, 100, 179, 0.25)';
    }

    if(annotation.hasIssue) {
      return 'rgba(255, 235, 59, 0.25)'
    }

    if(hideNonIssues) {
      return 'rgba(255, 255, 255, 0.0)';
    }

    return 'rgba(21, 100, 179, 0.25)';
  }

  const setBorder = (isActive: boolean) => {
    if(!hideDeleteButton && annotation.isHighlighted) {
      return 'solid var(--color-black) 2px';
    }

    if(annotation.isHighlighted) {
      return 'solid var(--color-black) 2px';
    // eslint-disable-next-line no-else-return
    } else {
      if(annotation.hasIssue && isActive) {
        return 'solid var(--color-black) 2px';
      }
      if(annotation.hasIssue && !isActive) {
        return 'solid var(--color-neutral-04) 1px';
      }
      if(!annotation.hasIssue && isActive) {
        return 'solid 0px';
      }

      return 'solid 0px';
    }
  }

  return (
    <Container
      key={key}
      className={className}
      style={{
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
        background: setBackgroundColor(active, hovered),
        border: setBorder(active),
        ...style,
      }}
    >
      {(hovered && active && !hideDeleteButton) && <Button onClick={() => onDelete()}>&#x2715;</Button>}
    </Container>
  );
}

Rectangle.propTypes = {
  key: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  annotation: PropTypes.shape({
    geometry: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
  active: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

Rectangle.defaultProps = {
  key: '',
  className: '',
  style: {},
  active: false,
};

export default Rectangle;
