/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { Col, Modal, Row, Typography, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { createPortal } from 'react-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import Navbar from './components/Navbar/Navbar';
import AdhocScan from './pages/AdhocScan/AdhocScan';
import DefineForms from './pages/DefineForms/DefineForms';
import FindScans from './pages/FindScans/FindScans';
import FlowTasks from './pages/FlowTasks/FlowTasks';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import { setUserData } from './store/user/userReducer';
import { AppDispatch, RootState } from './store/store';
import UnsavedChangesContext from './context/UnsavedChanges';
import { fetchConfigTypes } from './store/configTypes/configTypesReducer';
import LOCALIZATION from './localization';
import AccessDeniedContext from './context/AccessDenied';
import PageContainer from './components/styled/PageContainer';
import NavigationContext from './context/NavigationContext';

const RECOGNIZED_USER_ROLES = ['admin', 'reviewer'];

const DEFAULT_FIRST_PAGE_TITLE = 'Find Scans';

function App() {
  const [api] = notification.useNotification();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [trigger, setTrigger] = useState('null');
  const [triggerCallbackRef, setTriggerCallbackRef] = useState<Function>();
  const [cleanUpCallbackRef, setCleanupCallbackRef] = useState<Function>();
  const [modalTextsObject, setModalTextsObject] = useState<{title: string, message: string, okButton: string, cancelButton: string}>({
    title: LOCALIZATION.DISCARD_CHANGES_MODAL_TITLE,
    message: LOCALIZATION.DISCARD_CHANGES_MODAL_MESSAGE,
    okButton: LOCALIZATION.DISCARD_CHANGES_MODAL_OK_BUTTON,
    cancelButton: LOCALIZATION.CANCEL_BUTTON_TITLE
  })

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState<boolean>(false);
  const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);

  const [currentPageTitle, setCurrentPageTitle] = useState<string>(DEFAULT_FIRST_PAGE_TITLE)

  const dispatch = useDispatch<AppDispatch>();

  // get user roles from cognito    
  const getUserRoles = async () => {
    let roles = [] as string[];
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      roles = JSON.parse(cognitoUser.attributes['custom:role']) as string[];
      dispatch(setUserData({
        email: cognitoUser.attributes.email,
        identities: cognitoUser.attributes.identities,
        roles,
        isAdmin: roles.includes('admin'),
      }));  
    } catch(error) {
      console.warn(error);
    } finally {
      if(roles && RECOGNIZED_USER_ROLES.filter((recognizedRole: string) => roles.indexOf(recognizedRole) > -1).length === 0) {
        setShowAccessDeniedModal(() => true);
        setIsAccessDenied(() => true);
      }  
    }

  }

  const configTypesStoreError = useSelector((state: RootState) => state.configTypes.error);

  // refresh token every 30 minutes
  const refreshSession = async () => { 
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.getRefreshToken() as any, (err: any ) => { console.log(err) });
    getUserRoles();
  }

  useEffect(() => {
    getUserRoles();
    // fetch config types
    dispatch(fetchConfigTypes())

    // refresh token every 30 minutes
    const refreshInterval = setInterval(refreshSession, 1000 * 60 * 30);
    return () => clearInterval(refreshInterval);
  }, []);

    /**
   * Error handling
   */
  useEffect(() => {
    const errorMessage = configTypesStoreError;
    if(errorMessage?.length === 0 || errorMessage === null) {
      return;
    }

    api.error({
      message: LOCALIZATION.ERROR_OCCURED_TITLE,
      description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(':ERROR', errorMessage as string),
      placement: 'bottomLeft'
    });          
  }, [configTypesStoreError])
  
  return (
    <>
      <BrowserRouter>
        <AccessDeniedContext.Provider value={{
          isAccessDenied,
          triggerAccessDeniedModal: () => setShowAccessDeniedModal(() => true)
        }}>
          <UnsavedChangesContext.Provider value={{
            unsavedChanges,
            markUnsavedChanges: () => { setUnsavedChanges(() => true) },
            markSavedChanges: () => { setUnsavedChanges(() => false) },
            trigger,
            changeTrigger: () => setTrigger(() => uuidv4()),
            resetTrigger: () => {
              setTrigger(() => 'null');
              setModalTextsObject(() => ({
                title: LOCALIZATION.DISCARD_CHANGES_MODAL_TITLE,
                message: LOCALIZATION.DISCARD_CHANGES_MODAL_MESSAGE,
                okButton: LOCALIZATION.DISCARD_CHANGES_MODAL_OK_BUTTON,
                cancelButton: LOCALIZATION.CANCEL_BUTTON_TITLE        
              }))
            },
            setTriggerCallback: (callback: Function) => { setTriggerCallbackRef(() => callback) },
            triggerCallback: () => triggerCallbackRef ? triggerCallbackRef() : () => {},
            setTriggerModalTexts: (title, message, okButton, cancelButton) => {
              setModalTextsObject(() => ({ title, message, okButton, cancelButton}))
            },
            modalTexts: modalTextsObject,
            setCleanUpCallback: (callback: Function) => setCleanupCallbackRef(() => callback),
            cleanUpCallback: () => cleanUpCallbackRef ? cleanUpCallbackRef() : () => {}
          }}>
            <NavigationContext.Provider value={{
              currentPageTitle,
              updateCurrentPageTitle: (injectedPageTitle: string) => setCurrentPageTitle(() => injectedPageTitle)
            }}>
              <PageContainer gutter={[24,40]}>
                <Col style={{ padding: 0 }}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{paddingRight: '0px', minWidth: '80px', width: '80px'}}>
                      <Navbar/>
                    </div>
                    <div style={{padding: '0', width: 'calc(100vw - 80px)'}}>
                      {/* <h1 className='page-title'>
                        {currentPageTitle}
                      </h1> */}
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/find-scans" element={<FindScans />} />
                        <Route path="/define-forms" element={<DefineForms />} />
                        <Route path="/flow-tasks" element={<FlowTasks />} />
                        <Route path="/adhoc-scan" element={<AdhocScan />} />
                        <Route path="/" element={<Navigate to="/find-scans" />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </div>
                  </div>
                </Col>
              </PageContainer>
            </NavigationContext.Provider>
          </UnsavedChangesContext.Provider>
        </AccessDeniedContext.Provider>
      </BrowserRouter>

      {createPortal(
        <Modal
          open={showAccessDeniedModal}
          onOk={() => {
            setShowAccessDeniedModal(() => false);
            Auth.signOut();
          }}
          okText={LOCALIZATION.OK_BUTTON_TITLE}
          closable={false}
          cancelButtonProps={{
            style: {
              display: 'none'
            }
          }}
          maskStyle={{
            backgroundColor: 'var(--color-neutral-03)'
          }}
        >
          <Row>
            <Col span={2}>
              <CloseCircleOutlined style={{margin: '12px 0', fontSize: '20px', color: 'var(--color-danger)'}}/>
            </Col>
            <Col span={18}>
              <Row>
                <Col>
                  <Typography.Title style={{fontSize: '18px'}}>
                    {LOCALIZATION.ACCESS_DENIED_MODAL_TITLE}
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Text>
                    {LOCALIZATION.ACCESS_DENIED_MODAL_MESSAGE}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>,
        document.getElementById('modals') as HTMLElement
      )}        
    </>
  );
}

/**
 * 
 */
export default App;
