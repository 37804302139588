import { useState } from 'react';
import DatePicker from "react-datepicker";
import './DateSelector.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { func, object, string } from 'prop-types';
import LOCALIZATION from '../../localization';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault(dayjs.tz.guess());

interface DatePickerProps {
	defaultStartDateISO: string;
	defaultEndDateISO: string;
	outputFormat: string;
	onChange: (value: any) => void;
	enabledDateRange: {
		fromDateISO: string,
		untilDateISO: string
	}
}

interface DatePickerDates {
    startDate: any;
    endDate: any;
}

function DateSelector({
	defaultStartDateISO, 
	defaultEndDateISO, 
	outputFormat,
	onChange,
	enabledDateRange
}: DatePickerProps) {
    const [date, setDate] = useState<DatePickerDates>({
		startDate: defaultStartDateISO === null ? '' : new Date(defaultStartDateISO),
		endDate: defaultEndDateISO === null ? '' : new Date(defaultEndDateISO)
	})

	const checkDates = (dateArr: any) => {
		setDate(() => ({ startDate: dateArr[0] || "", endDate: dateArr[1] || "" }));

		onChange({
			startDate: dayjs(dateArr[0] || ""),
			endDate: dayjs(dateArr[1] || "")
		});
	}

	const onCalendarClose = () => {
		if (!date.endDate) {
			// const startDate = new Date(date.startDate);
			// let endDate = DateUtil.customDateFormat(new Date()) === DateUtil.customDateFormat(date.startDate) ? startDate : startDate.setDate(startDate.getDate() + 1);
            const endDate = null;
            setDate(() => ({ startDate: date.startDate, endDate }));

		}
	}

	return (
		<DatePicker
			selectsRange
			startDate={date.startDate}
			endDate={date.endDate}
			onChange={checkDates}
			onCalendarClose={onCalendarClose}
			dateFormat={outputFormat}
			showYearDropdown
			scrollableYearDropdown
			highlightDates={[date.startDate]}
			isClearable={date.startDate}
			formatWeekDay={(day: string) => day.substr(0,1)}
			includeDateIntervals={[
				{
					start: new Date(enabledDateRange.fromDateISO), 
					end: new Date(enabledDateRange.untilDateISO)
				}
			]}
			placeholderText={LOCALIZATION.SELECT_SCAN_DATE_RANGE_LABEL}
		/>
	);
};

DateSelector.defaultProps = {
	defaultStartDateISO: null,
	defaultEndDateISO: null,
	outputFormat: "MMM dd, yyyy",
	onChange: () => {},
	enabledDateRange: {
		fromDateISO: dayjs().tz(dayjs.tz.guess()).subtract(60, 'day').format(),
		untilDateISO: dayjs().tz(dayjs.tz.guess()).format()
	}
}

DateSelector.propTypes = {
	defaultStartDateISO: string,
	defaultEndDateISO: string,
	outputFormat: string,
	onChange: func,
	enabledDateRange: object
}

export default DateSelector;