import { createContext } from "react";

/**
 * A default values for context, where unsavedChanges is false by default and methods are references to empty functions for TypeScript's sake.
 *
 * @type {{ unsavedChanges: boolean; markUnsavedChanges: () => void; markSavedChanges: () => void; modalTexts: { title: string; message: string; okButton: string; cancelButton: string; }; setTriggerCallback: (callback: any) => void; ... 6 more ...; cleanUpCallback: () => void; }}
 */
const UNSAVED_CHANGES_DEFAULT = {
  unsavedChanges: false,
  markUnsavedChanges: () => {},
  markSavedChanges: () => {},

  modalTexts: { title: '', message: '', okButton: '', cancelButton: ''},

  setTriggerCallback: (callback: Function) => {
    callback()
  }, // "setTriggerCallback" sets a callback function evoked after user clicks on "Ok" button in Unsaved changes modal
  setTriggerModalTexts: (title: string, message: string, okButton: string, cancelButton: string) => {
    console.log(title,message,okButton,cancelButton)
  }, // "setTriggerTexts" changes, for only that one time, texts in modal window
  changeTrigger: () => {},  // "changeTrigger" is method evoked by outside method that needs to open Unsaved changes modal, after it sets callback with "setTriggerCallback"
  trigger: 'null',  //  "trigger" is the string, on which changes is listening Navbar to open Unsaved changes modal
  triggerCallback: () => {}, // "triggerCallback" is a callback method set by "setTriggerCallback"
  resetTrigger: () => {}, // "resetTrigger" is called by Navbar after "triggerCallback" is evoked to set trigger to the default state and help enable default behavior
  setCleanUpCallback: (callback: Function) => {
    callback();
  },  // set reference to actual screen's cleanUp method to reinit page if user clicks on the same page's link in NavBar
  cleanUpCallback: () => {}, // a callback method set by "setCleanUpCallback"
}

const UnsavedChangesContext = createContext(UNSAVED_CHANGES_DEFAULT);
UnsavedChangesContext.displayName = 'UnsavedChanges';

export default UnsavedChangesContext;