import React, { PureComponent as Component } from 'react';
import { getOffsetCoordPercentage } from './offsetCoordinates';

const withRelativeMousePos =
  (key = 'relativeMousePos') =>
  (DecoratedComponent: any) => {
    class WithRelativeMousePos extends Component<
      {},
      { x: number | null; y: number | null }
    > {
      static displayName: string;

      container: any;

      // eslint-disable-next-line react/state-in-constructor
      state = { x: null, y: null };

      onMouseMove = (e: React.MouseEvent) => {
        const xystate = getOffsetCoordPercentage(e);
        this.setState(xystate);
      };

      onTouchMove = (e: React.TouchEvent) => {
        if (e.targetTouches.length === 1) {
          const touch = e.targetTouches[0];

          const offsetX = touch.pageX - this.container.offsetParent.offsetLeft;
          const offsetY = touch.pageY - this.container.offsetParent.offsetTop;

          this.setState({
            x: (offsetX / this.container.width) * 100,
            y: (offsetY / this.container.height) * 100,
          });
        }
      };

      onMouseLeave = () => {
        this.setState({ x: null, y: null });
      };

      onTouchLeave = () => {
        this.setState({ x: null, y: null });
      };

      render() {
        const { x, y } = this.state;
        const hocProps = {
          [key]: {
            onMouseMove: this.onMouseMove,
            onMouseLeave: this.onMouseLeave,
            onTouchMove: this.onTouchMove,
            onTouchLeave: this.onTouchLeave,
            x,
            y,
          },
        };

        return <DecoratedComponent {...this.props} {...hocProps} />;
      }
    }

    WithRelativeMousePos.displayName = `withRelativeMousePos(${DecoratedComponent.displayName})`;

    return WithRelativeMousePos;
  };

export default withRelativeMousePos;
