import { Collapse } from "antd";
import styled from "styled-components";

/**
 * A styled Collapse.Panel component with stylized text and icons behavior.
 *  
 * @component 
 * 
 */
export default styled(Collapse).attrs(() => ({
    expandIconPosition: 'start',
    backgroundType: 'primary'
}))<{disable?: boolean, isSecondary?: boolean}>`
    background-color: ${props => props.isSecondary ? 'var(--color-primary-02)' : 'var(--color-primary-01)'};
    border: 0;
    border-radius:0;
    padding: 0 48px;
    width: 100%;

    & > .ant-collapse-item {
        border: 0;
        border-radius: 0;
        width: 100%;

        &:last-child {
            border-radius: 0;

            & > .ant-collapse-header {
                border-radius: 0;
            }    
        }

        & > .ant-collapse-header {
            background-color: ${props => props.isSecondary ? 'var(--color-primary-02)' : 'var(--color-primary-01)'};
            border: 0;
            border-radius: 0;
            border-bottom:  ${props => props.isSecondary ? '0' : '2px solid var(--color-extended-01);'};
            border-top:  ${props => props.isSecondary ? '2px solid var(--color-extended-01);' : '0'};
            color: var(--color-white);
            font-size: 18px;
            font-weight: 600;
            line-height: 34px;
            padding: 0;
            padding-top: 3px;
            padding-bottom: 3px;

            & .ant-collapse-header-text {
                line-height: 34px;
            }
    
            &.ant-collapse-icon-collapsible-only {
                & > .ant-collapse-expand-icon {
                    height: 34px;
                    & > span.anticon {
                        font-size: 18px;
                    }
                }
            }
        }
    
        & > .ant-collapse-content {
            background-color: ${props => props.isSecondary ? 'var(--color-primary-02)' : 'var(--color-primary-01)'};
            border-top: 0px;
            padding-top: 15px;
            
            & > .ant-collapse-content-box {
                padding: 0px;
            }    
        }
    }

    ${props => props.disable ?
       `& > .ant-collapse-item > .ant-collapse-header > .ant-collapse-expand-icon {
            display: none;
        }` : ``
    }
`