/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ApiStatus } from '../../interfaces/ApiStatus';
import { formsApi, TemplateOutput, TemplatesAllOutput } from '../../api/formsApi.service';
import { Record} from '../../interfaces/Record';


/**
 * An interface with keys for params to upload an image
 *
 * @interface UploadParams
 * @typedef {UploadParams}
 */
interface UploadParams {
    formBase64: string | null;
    imageExt: string;
    pageNo: string;
    transactionId?: string;
}


/**
 * An interface with keys for state of forms store
 *
 * @interface FormState
 * @typedef {FormState}
 */
interface FormState {
    list: Array<TemplateOutput>,
    status: ApiStatus | string,
    error: string | null,
    templateFields: Array<Record>,
    formDetails: any,
    imageName: string,
    formUploadPresignedURL: string,
    imageSrc: string,
    imageExt: string,
    imgS3Url: string,
    revision: string,
    transactionId?: string,
    retryStatus?: boolean,
}

/**
 * An initial state of forms store
 *
 * @type {FormState}
 */
const initialState: FormState = {
    list: [],
    status: 'idle',
    error: null,
    templateFields: [],
    formDetails: {},
    imageName: '',
    formUploadPresignedURL: '',
    imageSrc: '',
    imageExt: '',
    imgS3Url: '',
    revision: uuidv4(),
    retryStatus: true,
}


/**
 * An async thunk function to fetch all forms
 *
 * @type {*}
 */
export const fetchForms = createAsyncThunk('forms/fetchForms', async () => {
    const response = await formsApi.getAll();
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }

    return response;
})

/**
 * An async tunk to set status of store to loading.
 * Used for some operations, that do not instant change state to loading.
 *
 * @type {*}
 */
export const setLoading = createAsyncThunk('forms/setLoading', async () => 'loading')


/**
 * An async thunk function to upload an image as form 
 *
 * @type {*}
 */
export const uploadForm = createAsyncThunk('forms/uploadForm', async (params: UploadParams) => {
    const {formBase64, imageExt, pageNo, transactionId} = params;
    const response = await formsApi.analyzeForm(formBase64, imageExt, pageNo, transactionId);

    if(response.message && response.message !== 'success' &&
       response.message.length > 0) {
        throw new Error(response.message);
    }
    if (response.status === "in-progress") {
        return {
            ...response,
            imageSrc: "",
            textractArr: [],
            textractResults: null,
            s3ImageSrc: "",
            transactionId: response.transactionId,
        }
    }
    let textractResults: Array<Record> = [];
    if (response.textractResults) {
        const pages = Object.keys(response.textractResults).length;
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in response.textractResults) {
            const page = parseInt(i.split("_")?.[1], 10);
            const arr = response.textractResults[i].map((item) => ({ ...item, pageY: (page / pages).toFixed(2) }));
            textractResults = [...textractResults, ...arr];
        }
    }
    return {
      ...response,
      imageSrc: response.preSignImgUrl,
      textractArr: textractResults?.sort((a, b) => (a.geometry.y as number) - (b.geometry.y as number)),
      textractResults: response,
      s3ImageSrc: response.imageSrc,
    };
});
  

/**
 * A slice with reducers of forms store
 *
 * @type {*}
 */
export const forms = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        resetFormsStore: (state) => {
            state = initialState;
            return state;
        },
        updateFormName: (state, action) => {
            const foundForm = state.list.find(form => form.id === action.payload.form_id);
            if(foundForm) {
                foundForm.formName = action.payload.formName
            }
            state.revision = uuidv4();
        },
        addForm: (state, action) => {
            const foundForm = state.list.find(form => form.formType === action.payload.formType && form.formName === action.payload.formName);
            if(foundForm) {
                foundForm.status = action.payload.status;
                foundForm.outputLocation = action.payload.outputLocation;
                foundForm.outputSchema = action.payload.outputSchema;
                foundForm.lastUpdate = action.payload.lastUpdate;
            }
            state.revision = uuidv4();
        },
        updateTemplateFields: (state, action) => {
            state.templateFields = action.payload;
            state.revision = uuidv4();
        },
        updateImageSrc: (state, action) => {
            state.imageSrc = action.payload;
            state.revision = uuidv4();
        },
        setIsHighlightedField: (state, action) => {
            state.templateFields = state.templateFields.map(field => {
                field.isHighlighted = false;
                return field;
            });

            const foundField = state.templateFields.find(field => field.id === action.payload.id);
            if(foundField) {
                foundField.isHighlighted = true;
            }
            state.revision = uuidv4();
        },
        resetTemplateFields: (state) => {
            state.templateFields = [];
            state.revision = uuidv4();
        },
        resetFormError: (state) => {
            state.error = '';
            state.revision = uuidv4();
        },
        updateFormStatus: (state, action) => {
            state.status = action.payload;
            state.revision = uuidv4();
        },
        setRetryStatus: (state, action) => {
            state.retryStatus = action.payload;
            state.revision = uuidv4();
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchForms.pending, (state) => {
            state.status = 'loading';
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchForms.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list = action.payload.results as TemplateOutput[];
            state.list = action.payload.results
                .reduce((prevValue, currentValue: TemplatesAllOutput) => [...prevValue, currentValue.formTemplates.map((template: TemplateOutput) => ({ ...template, type: currentValue.type}))], [])
                .flat()
                state.revision = uuidv4();
                return state;
        })
        .addCase(fetchForms.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(uploadForm.pending, (state) => {
            state.templateFields = [];
            state.imageSrc = '';
            state.imageName = '';
            state.formUploadPresignedURL = '';
            state.status = 'loading';
            state.revision = uuidv4();
            return state;
        })
        .addCase(uploadForm.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(uploadForm.fulfilled, (state, action) => {
            state.templateFields = action.payload.textractArr;
            state.formDetails = action.payload.textractResults;
            state.imageSrc = action.payload.imageSrc;
            state.imgS3Url = action.payload.s3ImageSrc;
            state.status = action.payload.status || 'completed';
            state.revision = uuidv4();
            state.transactionId = action.payload.transactionId || '';
            return state;
        })
    }
})

export const {
  resetFormsStore,
  updateFormName,
  updateTemplateFields,
  addForm,
  updateImageSrc,
  setIsHighlightedField,
  resetTemplateFields,
  resetFormError,
  updateFormStatus,
  setRetryStatus,
} = forms.actions;

export default forms.reducer