/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Select, Form, Table, Tag, Space, Button, Typography, Spin, notification, Modal, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './FlowTasks.scss';
import styled from 'styled-components';
import { ExclamationCircleFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createPortal } from 'react-dom';
import StyledCollapse from '../../components/styled/StyledCollapse';
// import HeaderPanel from '../../components/styled/HeaderPanel';
import { AppDispatch, RootState } from '../../store/store';
import { fetchOutputs, fetchOutputBySchema } from '../../store/outputs/outputsReducer';
import { fetchForms } from "../../store/forms/formsReducer";
import FullWidthSpace from '../../components/styled/FullWidthSpace';
import { TemplateOutput } from "../../api/formsApi.service";
import { Field, FlowTask, flowTasksApi } from '../../api/flowTasksApi.service';
import colors from '../../colors';
import MatchFields from './components/MatchFields';
import { fetchFlowTaskByName } from '../../store/flowTasks/flowTasksReducer';
import BoundingBoxOverlay from '../../components/ImageAnnotation';
import useMapping from './hooks/useMapping';
import { Item, Mapping, Pair } from './types';
import LOCALIZATION from '../../localization';
import UnsavedChangesContext from '../../context/UnsavedChanges';
import AccessDeniedContext from '../../context/AccessDenied';
import PageContainer from '../../components/styled/PageContainer';
import PageHeader from '../../components/PageHeader/PageHeader';
import StyledSpaceWithTopMargin from '../../components/styled/StyledSpaceWithTopMargin';
import { RedAsterisk } from '../DefineForms/DefineForms';

const { confirm } = Modal;

const { Text } = Typography;

const DAYS_TO_SET_IDLE = 60;

const FLOW_TASKS_FORM_NAMES = {
  SELECT_TYPE: 'formType',
  SELECT_FORM: 'formName',
  OUTPUT_LOCATION: 'outputLocation',
  OUTPUT_SCHEMA: 'outputSchema',
  SUBMIT_BUTTON: 'submitButton'
}

interface SelectOptions {
  label: string;
  value: string;
}

// const StyledSpaceWithTopMargin = styled(Space)`
//   margin: 0;
//   position: absolute;
//   top: 50%;
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
//   width: 100%;
//   & .ant-space-item {
//     width: 100%;
//   }
// `

/**
 * Plus button in upper right corner used to switch to 'create new flow' mode.
 */
export const AddButton = styled(Button)`
  float: right;
  display: ${props => props.hidden ? 'none' : ''};
  &:hover {
    background-color: ${colors.bgButtonIdle} !important;
    .anticon {
      color: ${colors.textButtonHover}
    }
  }
`

/**
 * Save new flow task button with 'hidden' and 'disabled' props.
 */
export const OkButton = styled(Button).attrs(() => ({
  type: 'text',
  size: 'middle'
}))`
  background-color: ${props => props.disabled ? 'var(--color-accent-03)' : 'var(--color-accent-01)'} !important;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  // pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  display: ${props => props.hidden ? 'none' : ''};
  border-color: ${colors.bgButtonIdle};
  border-width: 2px
  border-radius: 3px;
  color: white;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  & span {
    font-family: Interstate;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.disabled ? 'var(--color-white)' : 'var(--color-white)'};
    vertical-align: text-top;
  }
  &:hover {
    background-color: ${props => props.disabled ? 'var(--color-accent-03)' : 'var(--color-accent-02)'} !important;
  }
  &:active {
    background-color: var(--color-accent-02) !important;
    border-color: var(--color-white);
  }

`

/**
 * Deactivate flow button.
 */
export const DeactivateButton = styled(Button).attrs(() => ({
  size: 'middle'
}))`
  display: ${props => props.hidden ? 'none' : ''};
  background-color: rgba(255,255,255,0);
  border-color: var(--color-white);
  border-width: 2px;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  & span {
    font-family: Interstate;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    display: ${props => props.disabled ? 'var(--color-accent-03)' : 'var(--color-white)'};
    vertical-align: text-top;
  }
  &:hover {
    border-color: var(--color-secondary-01) !important;
    & span {
      color: var(--color-secondary-01) !important;
    }
  }
  &:active {
    background-color: var(--color-neutral-01) !important;
    border-color: var(--color-secondary-01) !important;
    & span {
      color: var(--color-secondary-01) !important;
    }
  }
`

/**
 * Default states for the state manager.
 */
const managerDefault = {
  submitButtonDisabled: true,
  inputsDisabled: false,
  matchFieldsVisible: false,
  matchFieldsEditable: false,
  createNewFlow: false,
  formImage: false,
  visibleInputs: {
    [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: false,
    [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: false,
    [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: false
  }
}


/**
 * FlowTasks is a page used to create new flow tasks to match SAP fields with template fields in selected template.
 * That is crucial for submitting scans to SAP.
 * 
 * Relies on forms store (to populate form templates dropdown component), flowTasks store (to list and updated flow tasks), formTypes store (to populate form type dropdown component) and outputs store (to populate outputs location dropdown component).
 * 
 * Has one child component, MatchFields. See documentation in following section.
 *
 * @module FlowTasks
 */
function FlowTasks() {
  const [api, contextHolder] = notification.useNotification();

  const [flowTasksForm] = Form.useForm<FlowTask>();
  const [flowTasksMapping] = useMapping();
  
  const selectedFormType = Form.useWatch(FLOW_TASKS_FORM_NAMES.SELECT_TYPE, flowTasksForm);
  const [filteredFormTemplates, setFilteredFormTemplates] = useState<TemplateOutput[]>([]);

  const [outputLocations, setOutputLocations] = useState<SelectOptions[]>([]);
  const [outputFiles, setOutputFiles] = useState<SelectOptions[]>([]);

  const [selectedRow, setSelectedRow] = useState<TemplateOutput>();
  const [selectedFlow, setSelectedFlow] = useState<FlowTask>();
  const [allForManualReview, setAllForManualReview] = useState<boolean|undefined>(false);

  const [manager, setManager] = useState(managerDefault);

  const [mapping, setMapping] = useState([]);
  const [schemaFields, setSchemaFields] = useState<string[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const [showUndefinedModal, setShowUndefinedModal] = useState<boolean>(false);

  const formsStoreList = useSelector((state: RootState) => state.forms.list);
  const formsStoreStatus = useSelector((state: RootState) => state.forms.status);
  const formsStoreError = useSelector((state: RootState) => state.forms.error);

  const flowTaskSelectedList = useSelector((state: RootState) => state.flowTasks.list);
  const flowTaskSelectedStatus = useSelector((state: RootState) => state.flowTasks.status);
  const flowTaskSelectedError = useSelector((state: RootState) => state.flowTasks.error);

  const formTypesStoreList = useSelector((state: RootState) => state.formTypes.list);
  const formTypesStoreStatus = useSelector((state: RootState) => state.formTypes.status);
  const formTypesStoreError = useSelector((state: RootState) => state.formTypes.error);

  const outputsStoreList = useSelector((state: RootState) => state.outputs.list);
  const outputsStoreStatus = useSelector((state: RootState) => state.outputs.status);
  const outputsStoreError = useSelector((state: RootState) => state.outputs.error);

  const outputsStoreSchema = useSelector((state: RootState) => state.outputs.schema);

  const [ currentAnnotation, setCurrentAnnotation ] = useState<Field[]>([]); 
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

  const [discardChangesModal, setDiscardChangesModal] = useState<string>('');
  const [showDeactivatedFlows, setShowDeactivatedFlows] = useState<boolean>(false);
  const [isMatchFieldScrolledToBottom, setIsMatchFieldScrolledToBottom] = useState<boolean>(false);
  const [isFlowTasksTableOnBottom, setIsFlowTasksTableOnBottom] = useState<boolean>(false);

  const { markUnsavedChanges, markSavedChanges, setCleanUpCallback } = useContext(UnsavedChangesContext);
  const { isAccessDenied } = useContext(AccessDeniedContext);

  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const tableRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  const matchFieldsRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  const matchFieldsEditRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  
  const tempArr = flowTaskSelectedList?.[0]?.fields?.map(i => (i.pageY || 1)) || [];
  const tempHeight = Math.min(...tempArr) || 1;
  const [flowTasksLoading, setFlowTasksLoading] = useState(false);

  /**
   * Check if the flow task should be marked as 'Idle'.
   * 
   * @param date 
   * @returns Boolean
   */
  const isIdle = (date: number) => {
    if(moment().diff(moment(new Date(date * 1000)), 'days') >= DAYS_TO_SET_IDLE){
      return true;
    }
    return false
  }

  /**
   * Re-assigns TemplateOutput to add an order field, by which the array can be sorted.
   * 
   * @param item TemplateOutput
   * @returns String
   */

  /**
   * Handles onScroll event.
   */
  const onScroll = () => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsFlowTasksTableOnBottom(true);
      }
      else {
        setIsFlowTasksTableOnBottom(false);
      }
    }
    if (matchFieldsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = matchFieldsRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsMatchFieldScrolledToBottom(true);
      }
      else {
        setIsMatchFieldScrolledToBottom(false);
      }
    }
    if (matchFieldsEditRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = matchFieldsEditRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsMatchFieldScrolledToBottom(true);
      }
      else {
        setIsMatchFieldScrolledToBottom(false);
      }
    }
  }

  /**
   * Updates the flow tasks list in the table or right side.
   */
  useEffect(() => {
    setFilteredFormTemplates(() => formsStoreList
      .filter((form: TemplateOutput) => form.formType === selectedFormType && form.status.toUpperCase() !== 'ACTIVE'))

    if(!manager.createNewFlow && !selectedRow && formsStoreList.length > 0){
      const deactivatedFlows:any = [];
      const activeFlows:any = [];
      const filteredFormsStoreList = formsStoreList.map((item) => {
        if(item.status.toUpperCase() === 'INACTIVE'){
          deactivatedFlows.push(item);
        }
        if(item.status.toUpperCase() === 'ACTIVE'){
          activeFlows.push(item);
        }
        return item;
      // eslint-disable-next-line array-callback-return, consistent-return
      }).filter((form: TemplateOutput) => form.status !== 'NEW').filter((item) => {
        let forceShowDeactivatedFlows = showDeactivatedFlows;
        if(activeFlows.length === 0 && deactivatedFlows.length > 0) {
          forceShowDeactivatedFlows = true
          setShowDeactivatedFlows(true);
        }
        if(!forceShowDeactivatedFlows && !showDeactivatedFlows && item.status.toUpperCase() === 'ACTIVE'){
          return item;
        }
        if(forceShowDeactivatedFlows || showDeactivatedFlows) {
          return item;
        }
      }).sort((a, b) => +new Date(a.lastUpdate) - +new Date(b.lastUpdate))[0];

      if(!filteredFormsStoreList){
        setManager({...manager, 
          createNewFlow: true
        })
      }
      else {
        if (!flowTasksLoading) {
          setSelectedRow(filteredFormsStoreList);
  
          dispatch(fetchFlowTaskByName({
            formName: filteredFormsStoreList.formName, 
            formType: filteredFormsStoreList.formType
          }));
        }

        setManager({...manager, 
          visibleInputs: {
            [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: true,
            [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: true,
            [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: true,
          },
          formImage: true,
          matchFieldsVisible: false,
          matchFieldsEditable: false,
          submitButtonDisabled: true,
          inputsDisabled: true
        })
      }
    }
        
    if(matchFieldsRef.current){
      matchFieldsRef.current.addEventListener('scroll', onScroll, { passive: true });
    }
    if(matchFieldsEditRef.current){
      matchFieldsEditRef.current.addEventListener('scroll', onScroll, { passive: true });
    }
  }, [formsStoreList, selectedFormType]);

  useEffect(() => {
    if (flowTasksLoading) {
      const lastUpdated = formsStoreList.reduce((latest, current) => new Date(current.lastUpdate) > new Date(latest.lastUpdate) ? current : latest);
      const lastUpdatedActive = formsStoreList.reduce<TemplateOutput | null>((latest, current) => {
        if (current.status.toUpperCase() === 'ACTIVE' && (!latest || new Date(current.lastUpdate) > new Date(latest.lastUpdate))) {
          return current;
        }
        return latest;
      }, null);
      const latestFlow = showDeactivatedFlows ? lastUpdated : lastUpdatedActive;
      if (latestFlow && selectedFormType !== latestFlow.formType) {
        setSelectedRow(latestFlow);
        dispatch(fetchFlowTaskByName({
          formName: latestFlow.formName, 
          formType: latestFlow.formType
        }));
      }
      setFlowTasksLoading(false);
    }
  }, [formsStoreList]);

  /**
   * Sets output schema state when a new list is recieved.
   */
  useEffect(() => {
    if(outputsStoreSchema.length > 0) {
      setSchemaFields(outputsStoreSchema);
    }
  }, [outputsStoreSchema])

  /**
   * Sets output location state when a new list is recieved.
   */
  useEffect(() => {
    setOutputLocations(outputsStoreList.map(item => ({value: item.outputLocation, label: item.outputLocation})));
  }, [outputsStoreList])

  /**
   * Sets the selected flow tasks when the flow tasks list is updated and updates the left side of page.
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const selectedFlowFiltered = flowTaskSelectedList[0];
    if(flowTaskSelectedList.length === 0) {
      flowTasksForm.resetFields();
      return setSelectedRow(undefined);
    }
    if(manager.inputsDisabled){
      flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_FORM, selectedFlowFiltered.formName);
      flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_TYPE, selectedFlowFiltered.formType);
      flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION, selectedFlowFiltered.outputLocation);
      flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA, selectedFlowFiltered.outputSchema);
      setManager({...manager, createNewFlow: false, matchFieldsVisible: true});
      markSavedChanges();
    }
    if(selectedFlowFiltered && selectedFlowFiltered.mapping){
      setMapping(selectedFlowFiltered.mapping.mapped_fields);
    }
    setCurrentAnnotation([]);
    setSelectedFlow(selectedFlowFiltered);
    setAllForManualReview(selectedFlowFiltered?.allForManualReview);
  }, [flowTaskSelectedList])

  /**
   * Cleanup method used when returning to this page.
   */
  const cleanUp = () => {
    flowTasksForm.resetFields();
    setSelectedRow(undefined);
    setSelectedFlow(undefined);
    setManager(managerDefault);
    setSelectedFlow(undefined);
    markSavedChanges();
    dispatch(fetchForms());
  }

  useEffect(() => {
    setCleanUpCallback(cleanUp)
    if (formsStoreList.length === 0) {
      dispatch(fetchForms());
    }
    if (outputsStoreList.length === 0) {
      dispatch(fetchOutputs());
    }
    setFilteredFormTemplates(() => formsStoreList.filter(form => form.status !== 'Active'));
    
    tableRef.current.addEventListener('scroll', onScroll, { passive: true });
  }, []);

  /**
   * Sets create new flow task 'mode'.
   */
  const addNewFlowTask = () => {
    setManager({...managerDefault, visibleInputs: {
      [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: false,
      [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: false,
      [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: false,
    }, inputsDisabled: false, createNewFlow: true});
    flowTasksForm.resetFields();
    setSelectedRow(undefined);
  }

  /**
   * Call the API method for saving the new flow task.
   * 
   * @param flowTask FlowTask New flow task to save
   * @param successMessage string Success toast message
   * @param hideSuccessPopup boolean Should display success message
   * @returns API response
   */
  const callUpdateFlowTask = async (flowTask: FlowTask, successMessage?: string, hideSuccessPopup?: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await flowTasksApi.putFlowTaskByName(flowTask.formName, flowTask.formType, flowTask);
    if(response.message && response.message.length > 0) {
      api.error({
        message: LOCALIZATION.ERROR_OCCURED_TITLE,
        description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(':ERROR', response.message as string),
        placement: 'bottomLeft'
      });    
    }
    else if(!hideSuccessPopup) {
      api.success({
          message: successMessage,
          description: LOCALIZATION.FLOW_TASK_SUCCESS_TOAST_MESSAGE,
          placement: 'bottomLeft'
        });
    }
    return response;
  }

  /**
   * Submit/Save a new flow task.
   */
  const submitFlowTask = async () => {
    const newFlowTask: FlowTask = flowTasksForm.getFieldsValue();
    setFlowTasksLoading(true);

    newFlowTask.mapping = flowTasksMapping.getPairs().map((pair: Pair) => ({
      field_name_id: pair.rightId,
      field_name: pair.right,
      payload_field_name: pair.left
    }));

    newFlowTask.allForManualReview = allForManualReview;

    await callUpdateFlowTask(newFlowTask, LOCALIZATION.FIND_SCANS_FORM_SUBMIT_OK_TITLE);
    
    cleanUp();
  }

  /**
   * Deactivating the flow tasks.
   * 
   * @returns undefine if no flow tasks was selected
   */
  const deactivateFlowTask = async () => {
    setShowUndefinedModal(() => false);
    if(!selectedFlow){
      return;
    }
    const flowTask: FlowTask = {formName: selectedFlow.formName, formType: selectedFlow.formType};
    flowTask.status = 'INACTIVE';

    await callUpdateFlowTask(flowTask, LOCALIZATION.FLOW_TASK_INACTIVATE_TOAST_MESSAGE)
    
    dispatch(fetchForms());
    
    flowTasksForm.resetFields();
    setSelectedRow(undefined);
    setManager(managerDefault);
    setFlowTasksLoading(true);  
  }

  /**
   * Set flow task for a manual review.
   * 
   * @param value boolean Manual review value
   */
  const setManualReview = async (value: boolean) => {
    if(!selectedFlow){
      return;
    }
    const updateFlowTask = {formType: selectedFlow.formType, formName: selectedFlow.formName, status: selectedFlow.status, allForManualReview: value};
    const response = await callUpdateFlowTask(updateFlowTask, '', true);
    
    if(response.results?.length > 0) {
      setAllForManualReview(value);
      dispatch(fetchForms());
      dispatch(fetchFlowTaskByName({
        formName: selectedFlow.formName, 
        formType: selectedFlow.formType
      }));
      // setSelectedFlow({...updateFlowTask, allForManualReview: response.results[0].allForManualReview});
    }
  }

  useEffect(() => {
    if(isAccessDenied) {
      return;
    }
    
    const errorMessage = outputsStoreError || formsStoreError || formTypesStoreError || flowTaskSelectedError;
    if(errorMessage?.length === 0 || errorMessage === null) {
      return;
    }
    api.error({
      message: LOCALIZATION.ERROR_OCCURED_TITLE,
      description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(':ERROR', errorMessage as string),
      placement: 'bottomLeft'
    });          
  }, [outputsStoreError, formsStoreError, formTypesStoreError, flowTaskSelectedError])

  /**
   * Returns a status tag component.
   * 
   * @param status string|undefined Flow task status
   * @param forManualReview boolean|undefined Template set for a manual review
   * @param lastUsedDateTime number|undefined Flow tasks last used time
   * @returns Tag
   */
  const getTag = (status: string|undefined, forManualReview: boolean|undefined, lastUsedDateTime: number|undefined) => {
    if(lastUsedDateTime && lastUsedDateTime > 0 && isIdle(lastUsedDateTime) && status?.toUpperCase() === 'ACTIVE') {
      return <Tag color="default" className='flow-tasks-custom-tab flow-tasks-review-all-active-tag'>Idle</Tag>
    }
    if (status?.toUpperCase() === 'INACTIVE'){
      return <Tag color="default" className='flow-tasks-custom-tab flow-tasks-custom-tab-inactive'>Deactivated</Tag>
    }
    if(forManualReview) {
      return <Tag color="default" className='flow-tasks-custom-tab flow-tasks-review-all-active-tag'>Active (Review All)</Tag>
    }
    return <Tag color="default" className='flow-tasks-custom-tab'>Active</Tag>
  }

  /**
   * Sets and processes the state after the template selected.
   */
  const processTemplateSelect = () => {
    flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_FORM, undefined as unknown as string);
    setAllForManualReview(false);
    setManager({...manager, visibleInputs: {
      [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: true,
      [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: false,
      [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: false
      },
      matchFieldsVisible: false
    });
    markUnsavedChanges();
  }

  /**
   * Handles table row selection.
   * 
   * @param input 
   */
  const onRowHandler = (input?: any) => {
    setAllForManualReview(false);

    if(input){
      setSelectedRow(input.record);
      dispatch(fetchFlowTaskByName({
        formName: input.fields.formName, 
        formType: input.fields.formType
      }));
    } else if (selectedRecord) {
      setSelectedRow(selectedRecord.record);
      dispatch(fetchFlowTaskByName({
        formName: selectedRecord.fields.formName, 
        formType: selectedRecord.fields.formType
      }));
    }

    setManager({...manager, 
      visibleInputs: {
        [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: true,
        [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: true,
        [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: true,
      },
      formImage: true,
      matchFieldsVisible: false,
      matchFieldsEditable: false,
      submitButtonDisabled: true,
      inputsDisabled: true
    })
  }

  const getMatchFieldsClassName = () => {
    const classNames = ['flow-tasks-match-fields'];
    if (manager.matchFieldsEditable) {
      classNames.push('flow-tasks-match-fields-editable');
    }
    if (!isMatchFieldScrolledToBottom) {
      classNames.push('flow-tasks-match-fields-shadow');
      if (!isAdmin || isAccessDenied) classNames.push('flow-tasks-match-fields-view-shadow');
    }
    return classNames.join(' ');
  }

  // eslint-disable-next-line array-callback-return, consistent-return
  const getDataSource = () => formsStoreList.filter(form => form.status !== 'NEW').filter((item) => {
      if(!showDeactivatedFlows && item.status.toUpperCase() === 'ACTIVE'){
        return item;
      }
      if(showDeactivatedFlows) {
        return item;
      }
    }).sort((a, b) => +new Date(a.lastUpdate) - +new Date(b.lastUpdate))

  return (
    <PageContainer>
      <PageHeader title="Flow Tasks" button={
        <StyledSpaceWithTopMargin className='flow-tasks-button-wrapper'>
          <OkButton disabled={!isAdmin || isAccessDenied} hidden={!selectedRow} type="text" onClick={() => addNewFlowTask()} >
            <PlusOutlined /> Add Flow Task
          </OkButton>
        </StyledSpaceWithTopMargin>
      }>
    <Row className="flowTasksWrapper">
      {contextHolder}
      <Col span={24}>
        <StyledCollapse className='flow-tasks-content' disable collapsible="icon" defaultActiveKey={["1"]}>
          {/* <HeaderPanel header={
                <Row>
                  <Col className='flow-tasks-title' span={23}>
                    {LOCALIZATION.FLOW_TASKS_TITLE}
                    {!manager.createNewFlow && !isAdmin ? <Text className='flow-tasks-view-only-label'>
                      {' (View Only)'}
                    </Text> : null}
                  </Col>
                  <Col span={1}>
                    <AddButton disabled={!isAdmin || isAccessDenied} hidden={!selectedRow} size="small" type="text" onClick={() => addNewFlowTask()} >
                      <PlusOutlined />
                    </AddButton>
                  </Col>
                </Row>
              } key="1"> */}
            <Row>
              <Col span={12} className="flow-tasks-left-col">
                <Spin size="large" spinning={flowTaskSelectedStatus === 'loading'}>
                  <Row className='flow-tasks-left-col-content' style={{overflowY: manager.matchFieldsVisible && selectedFlow ? 'auto' : 'hidden'}}>
                    <Col span={24}>
                      <Row className='flow-tasks-detail-header-row'>
                        <Col span={20}>
                          <div className="flow-tasks-detail-header">
                            <Typography>
                              {!manager.inputsDisabled ? 'New Flow Task' : selectedFlow?.flowName}
                              {(!isAdmin || isAccessDenied) && (
                                  <span style={{ fontWeight: 'normal' }}>
                                    &nbsp;(<RedAsterisk>*</RedAsterisk>{LOCALIZATION.VIEW_ONLY_TEXT})
                                  </span>
                              )}
                            </Typography>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{paddingLeft: "12px"}}>
                        <Col span={24}>
                          <Form
                            form={flowTasksForm}
                            name="flow-tasks-form"
                            layout="vertical"
                          >
                            {!manager.createNewFlow ? <Row>
                              <Col span={24}>
                                {selectedFlow?.lastUsedDateTime && selectedFlow?.lastUsedDateTime > 0 && isIdle(selectedFlow?.lastUsedDateTime) && selectedFlow?.status?.toUpperCase() === 'ACTIVE' ?
                                <div className='flow-tasks-review-all flow-tasks-review-all-active flow-tasks-idle-box' hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]}>  
                                  <Row
                                    style={{ alignItems: 'center' }}
                                    justify="center">
                                    <Col xs={7} sm={5} md={4} lg={3} xl={2}>
                                      <ExclamationCircleFilled className='flow-tasks-idle-icon'/>
                                    </Col>
                                    <Col xs={17} sm={19} md={20} lg={21} xl={22}>
                                      <Text>
                                        {LOCALIZATION.FLOW_TASK_IDLE_MESSAGE}
                                      </Text>
                                    </Col>
                                  </Row>
                                </div> : null}
                                <div className={selectedFlow?.status?.toUpperCase() === 'INACTIVE' ? 'flow-tasks-review-all flow-tasks-review-all-disabled' : !allForManualReview ? 'flow-tasks-review-all' : 'flow-tasks-review-all flow-tasks-review-all-active'} hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]}>
                                  <Row
                                    style={{ alignItems: 'center' }}
                                    justify="center"
                                    gutter={10}>
                                      <Col xs={7} sm={5} md={4} lg={3} xl={3}>
                                        <Switch style={{
                                            verticalAlign: 'middle',
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                          }}
                                          onClick={(value) => {
                                            if(manager.createNewFlow) {
                                              setAllForManualReview(value)
                                            }
                                            else {
                                              confirm({
                                                icon: <ExclamationCircleOutlined style={{position: 'absolute'}}/>,
                                                content: 
                                                    <Text style={{width: '80%', float: 'right'}}>
                                                      {allForManualReview ? LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_OFF_MODAL_MESSAGE : LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_ON_MODAL_MESSAGE}
                                                    </Text>,
                                                okText: LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_BUTTON_TITLE,
                                                cancelText: LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_CANCEL_BUTTON_TITLE,
                                                onOk: () => setManualReview(value),
                                                onCancel() {
                                                  setAllForManualReview(!value);
                                                },
                                              });
                                            }
                                          }}
                                          checked={allForManualReview}
                                          disabled={!isAdmin || selectedFlow?.status?.toUpperCase() === 'INACTIVE' || isAccessDenied}
                                        />
                                      </Col>
                                      <Col xs={17} sm={19} md={20} lg={21} xl={21}>
                                        <Row className="flow-tasks-review-all-scans-title">
                                          <Text>{LOCALIZATION.FLOW_TASK_REVIEW_ALL_SCANS}</Text>
                                        </Row>
                                        <Row>
                                          <Text className="flow-tasks-description">{LOCALIZATION.FLOW_TASK_REVIEW_ALL_SCANS_DESCRIPTION}</Text>
                                        </Row>
                                      </Col>
                                  </Row>
                                </div>
                                {selectedFlow?.createdAt || selectedFlow?.lastUpdate ?
                                <Row className='flow-tasks-dates'>
                                  <Col span={7}>
                                    Created: {selectedFlow?.createdAt ? moment(new Date(parseFloat(selectedFlow?.createdAt) * 1000)).format("MM/DD/YY") : ''}
                                  </Col>
                                  <Col span={8}>
                                    Last Updated: {selectedFlow?.lastUpdate ? moment(new Date(parseFloat(selectedFlow?.lastUpdate) * 1000)).format("MM/DD/YY") : ''}
                                  </Col>
                                  <Col span={5} offset={4} style={{float: 'right'}}>
                                    {manager.inputsDisabled && selectedFlow?.status ? getTag(selectedFlow?.status, selectedFlow?.allForManualReview, selectedFlow.lastUsedDateTime) : null}
                                  </Col>
                                </Row> : null}
                              </Col>
                            </Row> : null}
                            <Row>
                              <Col span={12}>
                                <Row>
                                  <FullWidthSpace>
                                    <Form.Item label={manager.inputsDisabled ? LOCALIZATION.TYPE_LABEL : LOCALIZATION.SELECT_TYPE_LABEL} name={FLOW_TASKS_FORM_NAMES.SELECT_TYPE} rules={[{ required: true }]} required={!manager.inputsDisabled}>
                                      <Select
                                        placeholder={LOCALIZATION.SELECT_TYPE_LABEL}
                                        options={formTypesStoreList.map(type => ({label: type.type, value: type.type}))}
                                        loading={formTypesStoreStatus === 'loading'}
                                        onFocus={() => dispatch(fetchForms())}
                                        // eslint-disable-next-line consistent-return
                                        onChange={() => {
                                          if(flowTasksForm.getFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_FORM)){
                                            return setDiscardChangesModal(() => 'new-edit-form-select');
                                          }
                                          processTemplateSelect();
                                        }}
                                        onSelect={() => {
                                          
                                        }}
                                        disabled={manager.inputsDisabled}
                                        virtual={false}
                                      />
                                    </Form.Item>
                                  </FullWidthSpace>
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row>
                                  <FullWidthSpace>
                                    <Form.Item label={manager.inputsDisabled ? LOCALIZATION.FLOW_TASKS_FORM_LABEL : LOCALIZATION.FLOW_TASKS_SELECT_FORM_LABEL} name={FLOW_TASKS_FORM_NAMES.SELECT_FORM} rules={[{ required: true }]} required={!manager.inputsDisabled} hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.SELECT_FORM]}>
                                      <Select
                                        placeholder={LOCALIZATION.FLOW_TASKS_SELECT_FORM_LABEL}
                                        options={filteredFormTemplates.map(formTemplate => ({label: formTemplate.formName, value: formTemplate.formName}))}
                                        loading={formsStoreStatus === 'loading'}
                                        disabled={manager.inputsDisabled}
                                        onChange={() => {
                                          flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION, undefined as unknown as string);
                                          flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA, undefined as unknown as string);
                                          setAllForManualReview(false);
                                          setManager({...manager, visibleInputs: {
                                            [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: true,
                                            [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: true,
                                            [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: false
                                            },
                                            matchFieldsVisible: false,
                                            formImage: true
                                          });
                                          dispatch(fetchFlowTaskByName({
                                            formName: flowTasksForm.getFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_FORM), 
                                            formType: flowTasksForm.getFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_TYPE)
                                          }));
                                        }}
                                        virtual={false}
                                      />
                                    </Form.Item>
                                  </FullWidthSpace>
                                </Row>
                              </Col>
                            </Row>
                            {manager.createNewFlow ? <Row>
                              <Col span={24}>
                                <div className='flow-tasks-review-all' hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]}>
                                  <Row
                                    style={{ alignItems: 'center' }}
                                    justify="center"
                                    gutter={10}>
                                      <Col span={2}>
                                        <Switch style={{
                                            verticalAlign: 'middle',
                                          }}
                                          onClick={(value) => {
                                            if(manager.createNewFlow) {
                                              setAllForManualReview(value)
                                            }
                                            else {
                                              confirm({
                                                icon: <ExclamationCircleOutlined style={{position: 'absolute'}}/>,
                                                content: 
                                                    <Text style={{width: '80%', float: 'right'}}>
                                                      {allForManualReview ? LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_OFF_MODAL_MESSAGE : LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_ON_MODAL_MESSAGE}
                                                    </Text>,
                                                okText: LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_BUTTON_TITLE,
                                                cancelText: LOCALIZATION.FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_CANCEL_BUTTON_TITLE,
                                                onOk: () => setManualReview(value),
                                                onCancel() {
                                                  setAllForManualReview(!value);
                                                },
                                              });
                                            }
                                          }}
                                          checked={allForManualReview}/>
                                      </Col>
                                      <Col span={22}>
                                        <Row>
                                          <Text>{LOCALIZATION.FLOW_TASK_REVIEW_ALL_SCANS}</Text>
                                        </Row>
                                        <Row>
                                          <Text className="flow-tasks-description">{LOCALIZATION.FLOW_TASK_REVIEW_ALL_SCANS_DESCRIPTION}</Text>
                                        </Row>
                                      </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row> : null}
                            <Row>
                              <Col span={12}>
                                <FullWidthSpace>
                                  <Form.Item label={manager.inputsDisabled ? LOCALIZATION.OUTPUT_LOCATION_LABEL : LOCALIZATION.SELECT_OUTPUT_LOCATION_LABEL} name={FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION} rules={[{ required: true }]} required={!manager.inputsDisabled} hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]}>
                                    <Select
                                      placeholder={LOCALIZATION.OUTPUT_LOCATION_LABEL}
                                      options={outputLocations}
                                      loading={outputsStoreStatus === 'loading'}
                                      onChange={(value: string) => {
                                        const filter = outputsStoreList.filter(item => (item.outputLocation === value));
                                        setOutputFiles(filter[0].outputSchema.map(item => ({value: item, label: item})));
                                        setManager({...manager, 
                                          visibleInputs: {
                                            [FLOW_TASKS_FORM_NAMES.SELECT_FORM]: true,
                                            [FLOW_TASKS_FORM_NAMES.OUTPUT_LOCATION]: true,
                                            [FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]: true,
                                          }
                                        })
                                      }}
                                      disabled={manager.inputsDisabled}
                                      virtual={false}
                                    />
                                  </Form.Item>
                                </FullWidthSpace>
                              </Col>
                              <Col span={12}>
                                <FullWidthSpace>
                                  <Form.Item label={manager.inputsDisabled ? LOCALIZATION.OUTPUT_FILE_LABEL : LOCALIZATION.SELECT_OUTPUT_FILE_LABEL} name={FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA} rules={[{ required: true }]} required={!manager.inputsDisabled} hidden={!manager.visibleInputs[FLOW_TASKS_FORM_NAMES.OUTPUT_SCHEMA]}>
                                    <Select
                                      placeholder={LOCALIZATION.OUTPUT_FILE_LABEL}
                                      options={outputFiles}
                                      loading={outputsStoreStatus === 'loading'}
                                      onChange={(value) => {
                                        dispatch(fetchOutputBySchema(value));
                                        setManager({...manager, matchFieldsVisible: true, matchFieldsEditable: true});
                                      }}
                                      disabled={manager.inputsDisabled}
                                      virtual={false}
                                    />
                                  </Form.Item>
                                </FullWidthSpace>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                      { manager.matchFieldsVisible ?
                        <Row className={getMatchFieldsClassName()}>
                        { schemaFields.length > 0 && manager.matchFieldsEditable && selectedFlow ? 
                          <MatchFields 
                            ref={matchFieldsEditRef} 
                            templateFields={selectedFlow.fields ? selectedFlow.fields.map(((item: Field) => ({
                              id: item.id,
                              value: item.fieldName,
                              paired: false
                            }))) : []}
                            schemaFields={schemaFields.map(item => ({
                              value: item,
                              paired: false
                            }))}
                            mapping={mapping.map(((item: Mapping) => ({
                              rightId: item.field_name_id,
                              left: item.payload_field_name,
                              right: item.field_name
                            })))}
                            matchFields={flowTasksMapping} 
                            onRightSelected={(item: Item) => {
                              if(selectedFlow.fields){
                                const selected = selectedFlow.fields.filter((field) => {
                                  if (field.id === item.id && field.fieldName === item.value){
                                    return true;
                                  }
                                  return false
                                })
                                setCurrentAnnotation(selected)
                              }
                            }}
                            onPairSelected={(pair: Pair) => {
                              if(selectedFlow.fields){
                                const selected = selectedFlow.fields.filter((field) => {
                                  if (field.id === pair.rightId && field.fieldName === pair.right){
                                    return true;
                                  }
                                  return false
                                })
                                setCurrentAnnotation(selected)
                              }
                            }}
                            onMatch={(list) => {
                              setCurrentAnnotation([])
                              if(list.length === selectedFlow.fields?.length) {
                                setManager({...manager, submitButtonDisabled: false})
                              }
                            }}
                            onUnmatch={() => {
                              setCurrentAnnotation([])
                              setManager({...manager, submitButtonDisabled: true})
                            }}
                            editable/> 
                          : null }
                        { !manager.matchFieldsEditable && selectedFlow ? 
                          <MatchFields 
                            ref={matchFieldsRef} 
                            templateFields={selectedFlow.fields ? selectedFlow.fields.map(((item: Field) => ({
                              value: item.fieldName,
                              paired: false
                            }))) : []}
                            schemaFields={selectedFlow.schemaFields ? selectedFlow.schemaFields.map(((item: string) => ({
                              value: item,
                              paired: false
                            }))) : []}
                            mapping={mapping.map(((item: Mapping) => ({
                              left: item.payload_field_name,
                              right: item.field_name
                            })))}
                            matchFields={flowTasksMapping} /> : null}
                        </Row>
                      : null }
                    </Col>
                  </Row>
                  {isAdmin && <Row className='flow-tasks-left-col-button' justify='center'>
                    <Col xs={6} sm={6} md={6} lg={6} xl={8} />
                    <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                      {!manager.inputsDisabled ? 
                        <StyledSpaceWithTopMargin className='flow-tasks-button-wrapper'>
                          <OkButton disabled={manager.submitButtonDisabled || !isAdmin || isAccessDenied} onClick={submitFlowTask}>Submit Flow Task</OkButton>
                        </StyledSpaceWithTopMargin>
                      :
                        <StyledSpaceWithTopMargin className='flow-tasks-button-wrapper'>
                          <DeactivateButton hidden={!selectedFlow || selectedFlow?.status?.toUpperCase() === 'INACTIVE'  || !isAdmin || isAccessDenied} onClick={() => setShowUndefinedModal(() => true)}>{LOCALIZATION.DEACTIVATE_FLOW_BUTTON_TITLE}</DeactivateButton>
                          {selectedFlow && createPortal(
                            // eslint-disable-next-line react/jsx-no-undef
                            <Modal
                              centered
                              title={LOCALIZATION.FLOW_TASK_DEACTIVATION_MODAL_TITLE}
                              open={showUndefinedModal}
                              onOk={() => deactivateFlowTask()}
                              okText={LOCALIZATION.DEACTIVATE_FLOW_BUTTON_TITLE}
                              onCancel={() => setShowUndefinedModal(() => false)}
                              cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
                            >
                              <Space style={{padding: '30px 0'}}>
                                <Typography.Text>
                                  {LOCALIZATION.FLOW_TASK_DEACTIVATION_MODAL_MESSAGE + selectedFlow.formType}.
                                </Typography.Text>
                              </Space>
                            </Modal>,
                            document.getElementById('modals') as HTMLElement
                          )}          
                        </StyledSpaceWithTopMargin>
                      }
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={8} />
                  </Row>}
                </Spin>
              </Col>
              <Col className="flow-tasks-right-col" span={12}>
                <Row>
                  <Col span={24}>
                    <Table
                      className={!isFlowTasksTableOnBottom && getDataSource().length > 3 ? "flow-tasks-table flow-tasks-table-shadow" : "flow-tasks-table"}
                      ref={tableRef}
                      locale={{
                        emptyText: <Text className='flow-tasks-empty-placehoder' type="secondary">{LOCALIZATION.FLOW_TASKS_EMPTY_TABLE_PLACEHOLDER}</Text>
                      }}
                      columns={[{
                        title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_FLOW_NAME,
                        dataIndex: 'flowName',
                        key: 'flowName'
                      }, {
                        title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_FORM,
                        dataIndex: 'formName',
                        key: 'formName'
                      }, {
                        title: <><Text className='flow-tasks-show-deactivated-flows-text' type="secondary">Show Deactivated Flows</Text><Switch checked={showDeactivatedFlows} onChange={() => setShowDeactivatedFlows(!showDeactivatedFlows)}/></>,
                        dataIndex: 'status',
                        key: 'status',
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        render: (_, { status, createdAt, allForManualReview, lastUsedDateTime }) => ( 
                          <Row>
                            <Col span={12}>
                              { getTag(status, allForManualReview, parseFloat(lastUsedDateTime)) }
                            </Col>
                            <Col span={12}>
                              <Text className='flow-tasks-date-text' type="secondary">(created: {moment(new Date(parseFloat(createdAt) * 1000)).format("MM/DD/YY")})</Text>
                            </Col>
                          </Row>
                        ),
                        align: 'right' as 'right',
                      }]}
                      // eslint-disable-next-line array-callback-return, consistent-return
                      dataSource={!isAccessDenied ? getDataSource() : []}
                      onRow={(record) => ({
                        onClick: () => {
                          const fields = flowTasksForm.getFieldsValue();
                          Object.assign(fields, record);
                          if(!manager.inputsDisabled && flowTasksForm.getFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_FORM)){
                            
                            setSelectedRecord({record, fields});
                            setDiscardChangesModal(() => 'discard-by-table-select');
                          }
                          else {
                            onRowHandler({record, fields});
                          }
                        }
                      })}
                      loading={formsStoreStatus === 'loading'}
                      rowClassName={(record) => (selectedRow?.formName === record.formName && selectedRow?.formType === record.formType) ? 'selectedRow' : ''}
                      rowKey="id"
                      scroll={{
                        scrollToFirstRowOnChange: true
                      }}
                      size='small'
                      pagination={false}
                    />
                    <Row style={{height:"55vh"}}>
                      <Col span={24}>
                        <div className='flow-tasks-form-collapse'>
                              <Row className="flow-tasks-image-section">
                                <Col span={24} className="flow-tasks-bounding-box-overlay-col">
                                  {((manager.formImage && selectedFlow) || flowTaskSelectedStatus === 'loading') ?
                                    <Spin size="large" style={{paddingTop: '16px'}} spinning={flowTaskSelectedStatus === 'loading'}>
                                      {selectedFlow &&
                                        <BoundingBoxOverlay
                                          src={selectedFlow.imagePresignedUrl}
                                          alt=""
                                          className="flow-tasks-bounding-box-overlay"
                                          annotations={currentAnnotation.map((tf: Field) => ({
                                            ...tf,
                                            data: {
                                              id: tf.id
                                            },
                                            geometry: {
                                              height: tf.geometry.height * 100 * ((tf.pageY === 1 || !tf.pageY) ? 1 : tempHeight),
                                              width: tf.geometry.width * 100,
                                              x: tf.geometry.x * 100,
                                              y: tf.geometry.y * 100 * (tf.pageY || 1)
                                            }
                                          }))}
                                          value={{}}
                                        />
                                      }
                                    </Spin> : <Text className='flow-tasks-empty-placehoder' type="secondary">{LOCALIZATION.FLOW_TASKS_EMPTY_TEMPLATE_PLACEHOLDER}</Text>}
                                </Col>
                              </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          {/* </HeaderPanel> */}
        </StyledCollapse>
      </Col>
      {createPortal(
        <Modal
          centered
          title={LOCALIZATION.DISCARD_CHANGES_MODAL_TITLE}
          open={discardChangesModal !== ''}
          onOk={() => {
            if(discardChangesModal === 'discard-by-table-select') {
              onRowHandler();
              setDiscardChangesModal(() => '');
            }
            else {
              processTemplateSelect();
            }
            setDiscardChangesModal(() => '');
          }}
          okText={LOCALIZATION.DISCARD_CHANGES_MODAL_OK_BUTTON}
          onCancel={() => {
            flowTasksForm.setFieldValue(FLOW_TASKS_FORM_NAMES.SELECT_TYPE, selectedFlow?.formType)
            setDiscardChangesModal(() => '');
          }}
          cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
        >
          <Space style={{ padding: '30px 0' }}>
            <Typography.Text>
              {LOCALIZATION.DISCARD_CHANGES_MODAL_MESSAGE}
            </Typography.Text>
          </Space>
        </Modal>,
        document.getElementById('modals') as HTMLElement
      )}
    </Row>
    </PageHeader>
    </PageContainer>
  );
}

/**
 * 
 */
export default FlowTasks;