import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import HeaderPanel from './styled/HeaderPanel';

interface HeaderRowProps {
  leftSideText: string;
  rightSideText: string;
  children: React.ReactNode;
}

function HeaderRow({ leftSideText, rightSideText, children }: HeaderRowProps) {

    return (
        <>
            <HeaderPanel header={
                <Row>
                    <Col span={20}>{leftSideText}</Col>
                    <Col span={4}>{rightSideText}</Col>
                </Row>
            } key={2} />
            <Row>
                {children}
            </Row>
        </>
    );
}

HeaderRow.propTypes = {
  leftSideText: PropTypes.string,
  rightSideText: PropTypes.string,
};

HeaderRow.defaultProps = {
  leftSideText: '',
  rightSideText: ''
};

/** @component */
export default HeaderRow;
