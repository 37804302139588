import { ConfigProvider, Space, Table, Typography } from "antd";
import { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import LOCALIZATION from '../../../localization';
import { SearchScansResponseData } from "../../../api/scansApi.service";
import './ScansTable.scss';
import { resetFields } from "../../../store/fields/fieldsReducer";
import UnsavedChangesContext from "../../../context/UnsavedChanges";
import { setSorter } from "../../../store/scans/scansReducer";

interface ScansTableProps {
    /**
     * A reference to method from parent component to handle click on row.
     * 
     * @param {SearchScansResponseData} record A record from search scans response
     * @param {number} recordIndex An index of that record in list
     */
    onRowClick: (record: SearchScansResponseData, recordIndex: number) => void;
    /**
     * Inherited styles
     */
    style: React.CSSProperties;
    /**
     * A reference to record object, that should be selected, because of some other action.
     */
    rowToSelect: SearchScansResponseData;
    /**
     * A number of default page in pagination
     */
    defaultPage: number;
    /**
     * A reference to method from parent component to handle change of default page, because of some other action like scan submitting.
     * 
     * @param {number} updatedPage A number of new default page
     */
    updateDefaultPage: (updatedPage: number) => void;
    handleScansTableChange: (sorter: any) => void
}

/**
 * ScansTable is FindScans's child component with Antd components to display a paginated table with found scans.
 * Antd table offers pagination feature, but that is disabled and custom pagination is used instead.
 * 
 * Relies on scans store, which populates table with scans list and sets current page if searchScans action is completed.

 * This component is using props to exchange data and actions with parent component.
 * 
 * @module ScansTable
 */
function ScansTable({
    onRowClick,
    style,
    rowToSelect,
    defaultPage,
    updateDefaultPage,
    handleScansTableChange
}: ScansTableProps) {
    const [selectedRow, setSelectedRow] = useState<SearchScansResponseData>();
    const [currentPage, setCurrentPage] = useState<number>();

    const dispatch = useDispatch<AppDispatch>();

    const scansStoreList = useSelector((state: RootState) => state.scans.list);
    // const scansTotalCount = useSelector((state: RootState) => state.scans.totalRecordCount);
    const scansTotalCount = useSelector((state: RootState) => state.scans.list.length);
    const scansLastEvaluatedKey = useSelector((state: RootState) => state.scans.lastEvaluatedKey);
    const scanStorePaginationDetails = useSelector((state: RootState) => state.scans.paginationDetails);
    const scansStoreStatus = useSelector((state: RootState) => state.scans.status);
    const scansStoreStatusAction = useSelector((state: RootState) => state.scans.statusAction);

    const { unsavedChanges, setTriggerCallback, changeTrigger } = useContext(UnsavedChangesContext)

    const tableRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);

    const [isTableScrolledToBottom, setTableScrolledToBottom] = useState<boolean>(false);
    const sorter = useSelector((state: RootState) => state.scans.sorter);
    /**
     * Handler responding to scoll event on table
     */
    const onScroll = () => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setTableScrolledToBottom(() => true);
        }
        else {
          setTableScrolledToBottom(() => false);
        }
      }
    }

    /**
     * Handle select of row in scans table
     */
    useEffect(() => {
      if(!rowToSelect || (rowToSelect !== null && selectedRow?.id !== rowToSelect.id)) {
        dispatch(resetFields());
      }
      setSelectedRow(() => rowToSelect);        
    }, [rowToSelect]);

    /**
     * Watch status of searchScans async thunk in scans store
     * If completed, reset page in pagination to 1
     */
    useEffect(() => {
      if(scansStoreStatus === 'completed' && scansStoreStatusAction === 'searchScans') {
        setCurrentPage(() => 1);
      }

      tableRef.current = document.querySelector('#FindScans_ScansTable_Table_table .ant-table-body') || null as unknown as HTMLDivElement;
      if(tableRef.current){
        tableRef.current.addEventListener('scroll', onScroll, { passive: true });
      }
    }, [scansStoreStatus, scansStoreStatusAction])

    /**
     * Render "empty" text for scans table
     * @returns React.Component
     */
    const renderEmpty = () => (
      <Space><Typography style={{color: 'var(--color-neutral-05)', fontSize: '14px', margin: '60px 0'}}>{LOCALIZATION.FIND_SCANS_NO_RESULTS_TEXT}</Typography></Space>
    )

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const returnScrollProp = () => {
      if(scansStoreList.length > 0) {
        return selectedRow ? {y: 200} : {y: '60vh'}
      }
      return {}
    }

    const transformFileName = (text: any) => {
      const regex = /.*\/{1}([\w\d_-]+).\w{3,4}/;
      const m = regex.exec(text) as Array<string>;
      if (m?.length === 2) {
        return m[1];
      }
      return text;
    }

    const onChange = (_: any, __: any, _sorter: any) => {
      dispatch(setSorter({ columnKey: _sorter?.columnKey, order: _sorter?.order }));
      handleScansTableChange(_sorter);
    }

    /**
     * Watch status of scans store list
     * If changed, reset page in pagination to default page
     */
    useEffect(() => {
      setCurrentPage(() => defaultPage);
    }, [scansStoreList])


    return (
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          id="FindScans_ScansTable_Table_table"
          className={`ScansTable-table ${
            scansStoreList.length < 21 || isTableScrolledToBottom
              ? 'no-shadow'
              : ''
          }`}
          ref={tableRef}
          style={style}
          showSorterTooltip={false}
          columns={[
            {
              title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_ORIG_LOCATION,
              dataIndex: 'sourceFileObjectPath',
              key: 'sourceFileObjectPath',
              width: '30vw',
              render: (text) => transformFileName(text),
              sorter: true,
              sortOrder: sorter?.columnKey === 'sourceFileObjectPath' && sorter?.order,
              // sorter: (a, b) => {
              //   const fileName1 = transformFileName(a.sourceFileObjectPath);
              //   const fileName2 = transformFileName(b.sourceFileObjectPath);
              //   if (fileName1 < fileName2) {
              //     return -1;
              //   }
              //   if (fileName1 > fileName2) {
              //     return 1;
              //   }
              //   return 0;
              // },
            },
            {
              title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_SCAN_DATE,
              dataIndex: 'scanDate',
              key: 'scanDate',
              render: (text) => dayjs(text).format('MMM DD, YYYY, HH:mm:ss'),
              sorter: true,
              sortOrder: sorter?.columnKey === 'scanDate' && sorter?.order,
              // sorter: (a, b) => +new Date(a.scanDate) - +new Date(b.scanDate),
              width: '18vw',
            },
            {
              title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_ISSUES,
              dataIndex: 'issues',
              key: 'issues',
              // sorter: (a, b) => a.issues - b.issues,
              width: '8vw',
              render: (_, record: SearchScansResponseData) => record.issues,
            },
            {
              title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_FORM_STATUS,
              dataIndex: 'formStatus',
              key: 'formStatus',
              // sorter: (a, b) => {
              //   if (a.formStatus < b.formStatus) {
              //     return -1;
              //   }
              //   if (a.formStatus > b.formStatus) {
              //     return 1;
              //   }
              //   return 0;
              // },
              width: '16vw',
              render: (_, record: SearchScansResponseData) => record.formStatus,
              // render: (_, record: SearchScansResponseData) => {
              //   if (
              //     record.editedBy.indexOf('@') > -1 &&
              //     record.issues === 0 &&
              //     record.isSetForManualReview
              //   ) {
              //     return `${
              //       LOCALIZATION.FIND_SCANS_RESULTS_UNSUBMITTED_TEXT
              //     } (modified ${dayjs(record.lastUpdatedDateTime).format(
              //       'MM/DD/YY'
              //     )})`;
              //   }

              //   if (record.isSetForManualReview && record.issues === 0) {
              //     return LOCALIZATION.FIND_SCANS_RESULTS_UNSUBMITTED_TEXT;
              //   }

              //   return record.formStatus;
              // },
            },
            // {
            //   title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_SYSTEM_STATUS,
            //   dataIndex: 'systemStatus',
            //   key: 'systemStatus',
            //   sorter: (a, b) => {
            //     if (a.systemStatus < b.systemStatus) {
            //       return -1;
            //     }
            //     if (a.systemStatus > b.systemStatus) {
            //       return 1;
            //     }
            //     return 0;
            //   },
            //   width: '14vw',
            //   render: (_, record: SearchScansResponseData) => record.systemStatus,
            // },
            {
              title: LOCALIZATION.FIND_SCANS_RESULTS__HEADER_LAST_MODIFIED_DATE,
              dataIndex: 'lastUpdatedDateTime',
              key: 'lastUpdatedDateTime',
              render: (text, record: SearchScansResponseData) => {
                const scanDateTime = new Date(record.scanDate);
                const lastUpdatedDateTime = new Date(text);
                if (lastUpdatedDateTime < scanDateTime) {
                  return dayjs(record.scanDate).format('MMM DD, YYYY, HH:mm:ss');
                }
                return dayjs(text).format('MMM DD, YYYY, HH:mm:ss');
              },
              // sorter: (a, b) => +new Date(a.lastUpdatedDateTime) - +new Date(b.lastUpdatedDateTime),
              width: '18vw',
            },
          ]}
          loading={scansStoreStatus === 'loading'}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              if (unsavedChanges) {
                setTriggerCallback(() => {
                  setSelectedRow(record);
                  onRowClick(record, rowIndex as number);
                });
                changeTrigger();
              } else {
                setSelectedRow(record);
                onRowClick(record, rowIndex as number);
              }
            },
          })}
          dataSource={scansStoreList}
          pagination={{
            total: scansTotalCount,
            showTotal: (total, range) => scansLastEvaluatedKey ? `${range[0]}-${range[1]} of ${total}+ records`:`${range[0]}-${range[1]} of ${total} records`,
            pageSize: scanStorePaginationDetails.itemsPerPage,
            hideOnSinglePage: true,
            current: currentPage,
            defaultCurrent: defaultPage,
            onChange: (page: number) => {
              setCurrentPage(() => page);
              updateDefaultPage(page);
            },
            showSizeChanger: false,
            showTitle: false,
          }}
          rowClassName={(record) => {
            let classes = '';

            if (record && record.issues > 0) {
              classes = `${classes} has-issues`;
            }

            if (record.isSetForManualReview && record.issues === 0) {
              classes = `${classes} has-issues`;
            }

            if (record && record.id === selectedRow?.id) {
              classes = `${classes} selected-row`;
            }

            if (record && record.id === rowToSelect?.id) {
              classes = `${classes} selected-row`;
            }

            return classes;
          }}
          rowKey={(record) => record && record.id}
          scroll={returnScrollProp()}
          onChange={onChange}
          size="small"
        />
      </ConfigProvider>
    );
}

/**
 * 
 */
export default ScansTable;
