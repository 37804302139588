import { Space } from "antd";
import styled from "styled-components";

/**
 * A styled Space component with 100% width and 100% witdh of every ant-space-item
 *  
 * @component 
 * 
 */
export default styled(Space)`
    width: 100%;

    & .ant-space-item {
    width: 100%;
    }
`