import { createGlobalStyle } from "styled-components";

const GlobalCss = createGlobalStyle`
  :root {
    /* Primary colors */
    --color-primary-01: #031627;
    --color-primary-02: #0e263b;
    --color-secondary-01: #df134c;
    --color-accent-01: #178FFe;
    --color-accent-02: #40a9ff;
    --color-accent-03: #8fa1b1;
    --color-extended-01: #354552;
    --color-extended-02: #273c4f;

    --color-primary-transparent: rgba(33, 150, 243, 0.25);
    --color-primary-red-transparent: rgba(244, 67, 54, 0.25);
    --color-primary-yellow-transparent: rgba(255, 235, 59, 0.25);

    --color-primary-03: #1976D2; // deprecated
    --color-primary-04: #1564B3; // deprecated


    /* Secondary colors */
    --color-green-01: #4CAF50;
    --color-red-01: #F44336;
    --color-red-02: #fb4737;
    --color-yellow-01: #FFEB3B;
    --color-yellow-02: #ffe600;
    --color-black: #000000;
    --color-neutral-02: #424242;
    --color-neutral-03: #616161;
    --color-neutral-04: #757575;
    --color-neutral-05: #9E9E9E;
    --color-neutral-06: #BDBDBD;
    --color-neutral-07: #E0E0E0;
    --color-neutral-08: #EEEEEE;
    --color-neutral-09: #F5F5F5;
    --color-white: #FFFFFF;

    --color-match-fields-description: #757575;
    --color-match-fields-custom-item-border: #d9d9d9;

    /* Color usage */
    --color-success: var(--color-green-01);
    --color-warning: var(--color-yellow-01);
    --color-danger: var(--color-red-01);
    --color-info: var(--color-primary-02);
  }
`;

export default GlobalCss;