import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { EditorPropTypes } from '../types';

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-top: 2px;
  transform-origin: top right;
  animation: ${fadeInScale} 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
`;

const Button = styled.div`
  background: whitesmoke;
  border: 0;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  font-size: 0.7rem;
  margin: 0;
  outline: 0;
  padding: 6px 10px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: background 0.21s ease-in-out;
  &:focus,
  &:hover {
    background: #eeeeee;
  }
`;

const Row = styled.div`
  display: flex;
`;

/*
  Component for rendering controls under newly drawn bouding box
*/

function Editor({
  annotation,
  className,
  style,
  onSubmit,
  onCancel,
}: EditorPropTypes) {
  const { geometry } = annotation;
  if (!geometry) return null;

  return (
    <Container
      className={className}
      style={{
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height}%`,
        ...style,
      }}
    >
      <Row>
        <Button onClick={() => onSubmit()}>&#10003;</Button>
        <Button onClick={() => onCancel()}>&#x2715;</Button>
      </Row>
    </Container>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  annotation: PropTypes.shape({
    geometry: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: '',
  style: {},
};

export default Editor;
