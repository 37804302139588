/* eslint-disable eqeqeq */
import axios from "axios"
import { ApiResponse } from "../interfaces/ApiResponse";

/**
 * Interface for ConfigType data from configtypes lambda
 */
export interface ConfigType {
    display_name: string;
    name: string;
    return: string;
    regex_expression: string;
    suggest_dropdown?: boolean;
    dropdown_options?: Array<{label: string, value: string}>;
    return_values?: Array<string>;
    return_values_display?: Array<string>;
}

/**
 * Interface where list of ConfigTypes is added to ApiResponse 
 */
export interface ConfigTypesApiResponse extends ApiResponse{
    results: ConfigType[];
}

/**
 * An API service for retrieving and updating config types
 * 
 * @module api/configTypesApi.service
 */
export default {
    /**
     * Returns all config types with processed dropdown options
     * 
     * @returns {ConfigTypesApiResponse} Response with list of config types
     */
    getAll: async (): Promise<ConfigTypesApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/configtypes/all`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const alteredData = response.data.results.map((item: ConfigType) => {
                if(item.return === 'bool' || item.return_values) {
                    item.suggest_dropdown = true;
                    item.dropdown_options = item.return_values ? 
                        (item.return_values || []).map((option: string, optionIndex: number) => {
                            let returnValueDisplay = option;
                            if(item.return_values_display && item.return_values_display[optionIndex]) {
                                returnValueDisplay = item.return_values_display[optionIndex];
                            } 
                            return {
                                label: returnValueDisplay, 
                                value: option
                            }
                        }) as Array<{label: string, value: string}> : []
                } else {
                    item.suggest_dropdown = false;
                    item.dropdown_options = [];
                }
                return item;
            })
            return {
                ...response.data,
                results: alteredData
            };
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    }
}