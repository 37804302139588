import { SetStateAction, useState } from 'react';
import { Pair } from '../types';

export default function useMapping() {
    const [items, setItems] = useState<Pair[]>([])

    /**
     * Returns all pairs from mapping.
     * 
     * @returns Pair[] Array of pairs
     */
    function getPairs(): Pair[] {
        return items;
    }
    
    /**
     * Sets pairs to the mapping state.
     * 
     * @param values SetStateAction<Pair[]>
     */
    function setPairs(values: SetStateAction<Pair[]>) {
        setItems(values);
    }

    return [{
        getPairs, 
        setPairs
    }];
}
