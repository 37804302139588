import { Annotation, AnnotationGeometry } from '../types';
import { getCoordPercentage } from '../utils/offsetCoordinates';

export const TYPE = 'RECTANGLE';

export const intersects = (
  { x, y }: { x: number; y: number },
  geometry: AnnotationGeometry
) => {
  if (x < geometry.x) return false;
  if (y < geometry.y) return false;
  if (x > geometry.x + geometry.width) return false;
  if (y > geometry.y + geometry.height) return false;

  return true;
};

export const area = (geometry: AnnotationGeometry) =>
  geometry.height * geometry.width;

const pointerDown = (
  annotation: Annotation,
  e: React.TouchEvent | React.MouseEvent
) => {
  if (!annotation.selection) {
    const { x: anchorX, y: anchorY } = getCoordPercentage(e);
    return {
      ...annotation,
      selection: {
        ...annotation.selection,
        mode: 'SELECTING',
        anchorX,
        anchorY,
      },
    };
  }
  return {};
};

const pointerUp = (annotation: Annotation) => {
  if (annotation.selection) {
    const { selection, geometry } = annotation;
    if (!geometry) {
      return {};
    }
    switch (annotation.selection.mode) {
      case 'SELECTING':
        return {
          ...annotation,
          selection: {
            ...selection,
            showEditor: true,
            mode: 'EDITING',
          },
        };
      default:
        break;
    }
  }
  return annotation;
};

const pointerMove = (
  annotation: Annotation,
  e: React.TouchEvent | React.MouseEvent
) => {
  if (annotation.selection && annotation.selection.mode === 'SELECTING') {
    const { anchorX, anchorY } = annotation.selection;
    const { x: newX, y: newY } = getCoordPercentage(e);
    const width = newX - anchorX;
    const height = newY - anchorY;

    return {
      ...annotation,
      geometry: {
        ...annotation.geometry,
        type: TYPE,
        x: width > 0 ? anchorX : newX,
        y: height > 0 ? anchorY : newY,
        width: Math.abs(width),
        height: Math.abs(height),
      },
    };
  }
  return annotation;
};

export const methods = {
  onTouchStart(annotation: Annotation, e: React.TouchEvent) {
    return pointerDown(annotation, e);
  },
  onTouchEnd(annotation: Annotation) {
    return pointerUp(annotation);
  },
  onTouchMove(annotation: Annotation, e: React.TouchEvent) {
    return pointerMove(annotation, e);
  },
  onMouseDown(annotation: Annotation, e: React.MouseEvent) {
    return pointerDown(annotation, e);
  },
  onMouseUp(annotation: Annotation) {
    return pointerUp(annotation);
  },
  onMouseMove(annotation: Annotation, e: React.MouseEvent) {
    return pointerMove(annotation, e);
  },
};

const RectangleSelector = {
  TYPE,
  intersects,
  area,
  methods,
};

export default RectangleSelector;
