import axios from "axios"
import { ApiResponse } from "../interfaces/ApiResponse";
import { Record } from "../interfaces/Record";

/**
 * @module fieldsApi
 */

/**
 * Interface for mapped scanned field in response from lambda
 * 
 * @public
 * @interface
 * @exports ScannedFieldResponseData
 */
export interface ScannedFieldResponseData {
    "id": string;
    "key": string;
    "scannedValue": string;
    "acceptedValue": any;
    "confidence": number;
    "locked": boolean;
    "scanId": number;
    "formId": number;
    "pageY"?: any;
    "page"?: number;
    "requiredType": string;
    message: string;
    "boundingBox": Record;
    "confidenceThreshold"?: number;
    "isValid" ?: boolean;
    "isRequired"?: boolean;
    isUnreadable?: boolean;
    skipValidation?: boolean;
    accountIsValid?: any;
}

/**
 * An API service for retrieving and updating fields in template forms and scans
 * 
 * @module api/fieldsApi.service
 */
export default {
    /**
     * Get all scanned fields of scan selected by id of scan
     * 
     * @param {string} formType A type of form 
     * @param {string} scanId Id of selected scan
     * @returns {Promise<ApiResponse>} ApiResponse in promise
     */
    getByScanId: async (formType: string, scanId: string): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scan/byScanId/${formType}/${scanId}`
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const alteredResponseData = { ...response.data};
            alteredResponseData.results.map((result: ScannedFieldResponseData) => {
                const newResult = {...result};
                return newResult;
            })
            return alteredResponseData;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error?.response?.data?.message as string ||  error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    getFullFormByScanId: async (formType: string, scanId: string): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scan/fullForm/${formType}/${scanId}`
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const fullFormData = { ...response.data};
            return fullFormData;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error?.response?.data?.message as string ||  error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Get all scanned fields of template selected by name of template
     * 
     * @param {string} formType A type of form 
     * @param {string} templateName A name of the selected template
     * @returns {Promise<ApiResponse>} ApiResponse in promise
     */
    getByFormId: async (formType: string, templateName: string): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/template/byName/${formType}/${templateName}`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return {
                results: response.data.results.map((dataObj: { fields: any[]; }) => ({
                    ...dataObj,
                    fields: dataObj.fields.map(fieldObj => ({
                        ...fieldObj,
                        boundingBox: {
                            ...fieldObj.boundingBox,
                            id: fieldObj.id,
                            name: fieldObj.fieldName,
                            type: fieldObj.requiredType,
                            confidence: fieldObj.confidence
                        }
                    }))
                }))
            }
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    // Check validity of the Account ID from SAP
    checkAccIdValidity: async (id: string, sourceFileObjectPath: string): Promise<any> => {
        try {
            const encodedFilePath = encodeURIComponent(sourceFileObjectPath) || null;
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scan/byAccountId/${id}/${encodedFilePath}`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error) && error.response) {
                return error.response.data; 
            }
            return {
                message: error as string
            };   
        }
    }
}