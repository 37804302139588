// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from "@reduxjs/toolkit";
import flowTasksReducer from "./flowTasks/flowTasksReducer";
import formsReducer from "./forms/formsReducer";
import fieldsReducer from "./fields/fieldsReducer";
import scansReducer from "./scans/scansReducer";
import formTypesReducer from "./formTypes/formTypesReducer";
import outputsReducer from "./outputs/outputsReducer";
import userReducer from "./user/userReducer";
import configTypesReducer from "./configTypes/configTypesReducer";

export const store = configureStore({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'scans/searchScans/fulfilled',
                    'scans/refilterList'
                ]
            }
        }),
    reducer: {
        formTypes: formTypesReducer,
        flowTasks: flowTasksReducer,
        forms: formsReducer,
        fields: fieldsReducer,
        scans: scansReducer,
        outputs: outputsReducer,
        user: userReducer,
        configTypes: configTypesReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
