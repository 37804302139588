import { createContext } from "react";

const NAVIGATION_DEFAULT = {
  currentPageTitle: '',
  updateCurrentPageTitle: (injectedPageTitle: string) => console.log(injectedPageTitle)
}

const NavigationContext = createContext(NAVIGATION_DEFAULT);
NavigationContext.displayName = 'Navigation';

export default NavigationContext;