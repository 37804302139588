import axios from 'axios'
import { Auth } from 'aws-amplify'

axios.interceptors.request.use(
  async config => {
    try {
      const userSession = await Auth.currentSession();
      const token = userSession.getIdToken().getJwtToken();
      config.headers.Authorization = token
      return config
    } catch (error) {
      throw new Error('Failed to set authorization token')
    }
  },
  error => {
    Promise.reject(error)
  }
)

const AwsConfigAuth = {
    region: process.env.REACT_APP_COGNITO_REGION || 'us-west-2',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'us-west-2_TS1L7g4yq',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ||'1gvo6aeq1ppf2mmjnugklgud9g',
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN ||'ocr-qa-1.auth.us-west-2.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'openid',
          'aws.cognito.signin.user.admin',
          'profile'
        ],
        redirectSignIn:  `${window.location.origin}/find-scans`,
        redirectSignOut:  `${window.location.origin}/find-scans`,
        responseType: 'code'
      }
};

export default AwsConfigAuth;

