import axios from "axios"
import { ApiResponse } from "../interfaces/ApiResponse";

/**
 * An interface descriginb output data in response
 *
 * @export
 * @interface FetchOutputsResponseData
 * @typedef {FetchOutputsResponseData}
 */
export interface FetchOutputsResponseData {
    /**
     * @type {string}
     */
    "outputLocation": string;
    /**
     * @type {string[]}
     */
    "outputSchema": string[];
}

/**
 * An API service for retrieving and updating outputs for flow tasks
 * 
 * @module api/outputsApi.service
 */
export default {
    /**
     * Fetch output by schema
     * @param {string} schema 
     * @returns {Promise<ApiResponse>} A general API response with data
     */
    getBySchema: async (schema: string): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/output`;
            const response = await axios.post(path, JSON.stringify({
                outputSchema: schema
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Get a list of all outputs
     * @returns {Promise<ApiResponse>} A general API response with data
     */
    getAll: async (): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/outputs/all`;;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    }
}