import { theme } from "antd";

export default {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimaryBg: 'var(--color-primary-01)',
        colorPrimaryBgHover: 'var(--color-primary-02)',
        colorBorder: 'var(--color-white)'
    },
    components: {
        Switch: {
            colorPrimary: 'var(--color-primary-01)',
            colorTextQuaternary: 'rgba(245, 245, 245, 1.0)',
            colorTextTertiary: 'rgba(245, 245, 245, 1.0)',
            colorWhite: 'var(--color-neutral-04)',
            colorPrimaryBorder: 'var(--color-neutral-04)'
        },
        Button: {
            colorPrimary: 'var(--color-accent-01)',
            colorPrimaryHover: 'var(--color-accent-02)',
            borderRadiusLG: 4,
            colorTextDisabled: 'var(--color-neutral-07)',
            colorBgContainerDisabled: 'var(--color-neutral-06)',
            fontSizeLG: 14
        },
        Table: {
            borderRadius: 0,
            borderRadiusLG: 0,
            colorBorderSecondary: 'transparent',
            colorFillQuaternary: 'var(--color-primary-01)',
            colorText: 'var(--color-white)',
            colorBgContainer: 'var(--color-primary-01)',
            colorFillAlter: 'var(--color-primary-01)',
            colorFillSecondary: 'var(--color-primary-01)',
        },
        Select: {
            borderRadiusLG: 4,
            controlHeightLG: 42,
            colorTextPlaceholder: 'var(--color-neutral-07)',
            colorBgElevated: 'var(--color-primary-01)',
            controlItemBgHover: 'var(--color-accent-01)',
            fontSizeLG: 12
        },
        Radio: {
            dotSize: 10,
        }
    }
}