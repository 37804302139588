import React, { Component } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import compose from '../utils/compose';
import withRelativeMousePos from '../utils/withRelativeMousePos';

import defaultProps from './defaultProps';
import { AnnotationProps, Annotation as AnnType } from '../types';

const Container = styled.div`
  clear: both;
  position: relative;
  width: 100%;
`;

const Img = styled.img`
  display: block;
  width: 100%;
`;

const Items = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Target = Items;

export default compose(withRelativeMousePos())(
  class Annotation extends Component<AnnotationProps> {
    static propTypes = {
      onMouseMove: T.func,
      children: T.object,

      annotations: T.arrayOf(
        T.shape({
          type: T.string,
        })
      ).isRequired,
      selector: T.shape({
        TYPE: T.string,
        intersects: T.func.isRequired,
        area: T.func.isRequired,
        methods: T.object.isRequired,
      }).isRequired,
      value: T.shape({
        selection: T.object,
        geometry: T.shape({
          type: T.string.isRequired,
        }),
        data: T.object,
      }),
      onChange: T.func,
      onSubmit: T.func,
      onCancel: T.func,
      onDelete: T.func,
      onBoxClick: T.func,
      activeAnnotationComparator: T.func,
      activeAnnotations: T.arrayOf(T.any),
      disableAnnotation: T.bool,
      renderSelector: T.func,
      renderEditor: T.func,
      renderOutline: T.func.isRequired,
      allowTouch: T.bool,
      relativeMousePos: T.object,
      src: T.any,
      alt: T.string,
      className: T.string,
      style: T.object,
    };

    static defaultProps = { ...defaultProps };

    targetRef: React.RefObject<HTMLInputElement> = React.createRef();

    componentDidMount() {
      const { allowTouch } = this.props;
      if (allowTouch) {
        this.addTargetTouchEventListeners();
      }
    }

    componentDidUpdate(prevProps: AnnotationProps) {
      const { allowTouch } = this.props;
      if (allowTouch !== prevProps.allowTouch) {
        if (allowTouch) {
          this.addTargetTouchEventListeners();
        } else {
          this.removeTargetTouchEventListeners();
        }
      }
    }

    // eslint-disable-next-line class-methods-use-this
    addTargetTouchEventListeners = () => {
      // Safari does not recognize touch-action CSS property,
      // so we need to call preventDefault ourselves to stop touch from scrolling
      // Event handlers must be set via ref to enable e.preventDefault()
      // https://github.com/facebook/react/issues/9809
      // this.targetRef.current!.ontouchstart = this.onTouchStart;
      // this.targetRef.current!.ontouchend = this.onTouchEnd;
      // this.targetRef.current!.ontouchmove = this.onTargetTouchMove;
      // this.targetRef.current!.ontouchcancel = this.onTargetTouchLeave;
    };

    removeTargetTouchEventListeners = () => {
      this.targetRef.current!.ontouchstart = undefined;
      this.targetRef.current!.ontouchend = undefined;
      this.targetRef.current!.ontouchmove = undefined;
      this.targetRef.current!.ontouchcancel = undefined;
    };

    getTopAnnotationAt = (x: number | null, y: number | null) => {
      const { annotations } = this.props;
      const { selector } = this.props;

      const intersections = annotations
        .map((annotation) => {
          const { geometry } = annotation;

          return selector.intersects({ x, y }, geometry) ? annotation : {};
        })
        .filter((a) => a.geometry)
        .sort(
          (a: any, b: any) =>
            selector.area(a.geometry) - selector.area(b.geometry)
        );

      return intersections[0];
    };

    onTargetMouseMove = (e: React.MouseEvent) => {
      const { relativeMousePos } = this.props;
      relativeMousePos.onMouseMove(e);
      this.onMouseMove(e);
    };

    onTargetTouchMove = (e: React.TouchEvent) => {
      const { relativeMousePos } = this.props;
      relativeMousePos.onTouchMove(e);
      this.onTouchMove(e);
    };

    onTargetMouseLeave = (e: React.MouseEvent) => {
      const { relativeMousePos } = this.props;
      relativeMousePos.onMouseLeave(e);
    };

    onTargetTouchLeave = (e: React.TouchEvent) => {
      const { relativeMousePos } = this.props;
      relativeMousePos.onTouchLeave(e);
    };

    onMouseUp = (e: React.MouseEvent) =>
      this.callSelectorMethod('onMouseUp', e);

    onMouseDown = (e: React.MouseEvent) =>
      this.callSelectorMethod('onMouseDown', e);

    onMouseMove = (e: React.MouseEvent) =>
      this.callSelectorMethod('onMouseMove', e);

    onTouchStart = (e: React.TouchEvent) =>
      this.callSelectorMethod('onTouchStart', e);

    onTouchEnd = (e: React.TouchEvent) =>
      this.callSelectorMethod('onTouchEnd', e);

    onTouchMove = (e: React.TouchEvent) =>
      this.callSelectorMethod('onTouchMove', e);

    onClick = (e: React.MouseEvent) => {
      this.callSelectorMethod('onClick', e)
    };

    onSubmit = () => {
      const { onSubmit, value } = this.props;
      onSubmit(value);
    };

    onDelete = (annotation: AnnType) => {
      const { onDelete } = this.props;
      if(onDelete) {
        onDelete(annotation);
      }
    };

    callSelectorMethod = (
      methodName: string,
      e: React.TouchEvent | React.MouseEvent
    ) => {
      const {
        relativeMousePos,
        disableAnnotation,
        onChange,
        value,
        [methodName]: method,
      } = this.props;

      if (disableAnnotation) {
        return;
      }

      if (method) {
        method(e, {x: relativeMousePos.x, y: relativeMousePos.y});
      } else {
        const { selector } = this.props;
        if (selector && selector.methods[methodName]) {
          const selectorValue = selector.methods[methodName](value, e);
          onChange(selectorValue);
        }
      }
    };

    // eslint-disable-next-line class-methods-use-this
    shouldAnnotationBeActive = (annotation: AnnType) => annotation.isHighlighted;

    shouldAnnotationBeHovered = (annotation: AnnType, top: AnnType) => {
      const { activeAnnotations, activeAnnotationComparator } = this.props;
      if (activeAnnotations) {
        const isActive = activeAnnotations.some((active) =>
          activeAnnotationComparator(annotation, active)
        );

        return isActive || top === annotation || annotation.isHighlighted;
      }
      return top === annotation || annotation.isHighlighted;
    };

    render() {
      const {
        renderOutline,
        renderSelector,
        renderEditor,
        allowTouch,
        style,
        className,
        alt,
        src,
        annotations,
        value,
        onChange,
        onCancel,
        children,
        relativeMousePos,
        onDelete,
        hideNonIssues
      } = this.props;

      const topAnnotationAtMouse = this.getTopAnnotationAt(
        relativeMousePos.x,
        relativeMousePos.y
      );

      return (
        <Container
          style={{
            ...style,
            touchAction: `${allowTouch ? 'pinch-zoom' : 'auto'}`,
          }}
          onMouseLeave={this.onTargetMouseLeave}
          onTouchCancel={this.onTargetTouchLeave}
        >
          <Img
            className={className}
            style={style}
            alt={alt}
            src={src}
            draggable={false}
          />
          <Items>
            {annotations.map((annotation) => renderOutline({
                  key: annotation.data?.id,
                  annotation,
                  active: this.shouldAnnotationBeActive(
                    annotation
                  ),
                  hovered: this.shouldAnnotationBeHovered(
                    annotation,
                    topAnnotationAtMouse
                  ),
                  onDelete: () => this.onDelete(annotation),
                  hideDeleteButton: onDelete === undefined,
                  hideNonIssues
                })
            )}
            {renderSelector({
              annotation: value,
            })}
          </Items>
          <Target
            onClick={this.onClick}
            onMouseUp={this.onMouseUp}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onTargetMouseMove}
          />
          {value &&
            value.selection &&
            value.selection.showEditor &&
            renderEditor({
              annotation: value,
              onChange,
              onSubmit: this.onSubmit,
              onCancel,
            })}
          <div>{children}</div>
        </Container>
      );
    }
  }
);
