/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ApiStatus } from '../../interfaces/ApiStatus';
import formsApi, { SearchScansParams, SearchScansResponseData } from '../../api/scansApi.service';
import { ScannedFieldResponseData } from '../../api/fieldsApi.service';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

/**
 * An interface with keys for pagination state
 *
 * @interface ScansPagination
 * @typedef {ScansPagination}
 */
interface ScansPagination { 
    itemsPerPage: number;
    limit: number;
    pages: number;
    findScansCurrentPage: number;
}

interface LastEvaluatedKey {
    CreatedDateTime: any;
    FormType?: string;
    TransactionID?: string;
}

interface Sorter {
    columnKey: any;
    order: any;
}

/**
 * An interface with keys for scans store state
 *
 * @interface ScansState
 * @typedef {ScansState}
 */
interface ScansState {
    unfilteredList: Array<SearchScansResponseData>,
    list: Array<SearchScansResponseData>,
    listByFormType: string,
    paginationDetails: ScansPagination,
    scanPresignedURL: string,
    status: ApiStatus,
    statusAction: string,
    error: string | null,
    errorResults?: any;
    revision: string,
    reducerAction: string,
    totalPages?: number,
    pdfPresignedURL?: string,
    currentPageNumber?: number,
    currentPageRecordCount?: number,
    lastEvaluatedKey?: LastEvaluatedKey,
    sorter?: Sorter,
}

/**
 * An initial state of scans store
 *
 * @type {ScansState}
 */
const initialState: ScansState = {
    unfilteredList: [],
    list: [],
    listByFormType: '',
    paginationDetails: {
        itemsPerPage: 20,
        limit: 100,
        pages: 0,
        findScansCurrentPage: 1
    },
    scanPresignedURL: '',
    status: 'idle',
    statusAction: 'none',
    error: null,
    revision: uuidv4(),
    reducerAction: '',
    pdfPresignedURL: '',
    sorter: { columnKey: "", order: "" },
}

/**
 * An async thunk function to fetch scans according to search values and pagination
 *
 * @type {*}
 */
export const searchScans = createAsyncThunk('scans/searchScans', async (searchParams: SearchScansParams) => {
    const response = await formsApi.getBySearch(searchParams);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    return { response, searchParams };
})

/**
 * The function saving or submitting modified scan
 *
 * @async
 * @param {*} payload
 * @returns {unknown}
 */
const saveSubmitScan = async (payload: any) => {
    const alteredPayload = {
        ...payload,
        fields: payload.fields.map((field: ScannedFieldResponseData) => ({
            ...field,
            acceptedValue: field.isUnreadable ? '#unreadable' : field.acceptedValue
        }))
    }

    const response = await formsApi.postSave(alteredPayload);
    if(response.message && response.message.length > 0 && response.results.length === 0) {
        if(!response.status || response.status !== 202) {
            throw new Error(response.message);
        }
    }
    return response;
}

/**
 * An async thunk function to save modified scan
 *
 * @type {*}
 */
export const saveScan = createAsyncThunk('scan/save', saveSubmitScan)

/**
 * An async thunk function to submit scan
 *
 * @type {*}
 */
export const submitScan = createAsyncThunk('scan/submit', saveSubmitScan)

/**
 * A function remapping scan values from respons to type used in components
 *
 * @param {{ transactionId: any; imageLocation: any; scanDate: any; issues: string; formType: any; isSubmitted: any; isSetForManualReview: any; lastUpdatedDateTime: any; lastUpdatedDateTimeSec: any; submittedBy: any; editedBy: any; sourceFileObjectPath: any;}} result
 * @returns {{ ...; }}
 */
const remapSearchResults = (result: { transactionId: any; imageLocation: any; scanDate: any; issues: string; formType: any; isSubmitted: any; isSetForManualReview: any; lastUpdatedDateTime: any; lastUpdatedDateTimeSec: any; submittedBy: any; editedBy: any; sourceFileObjectPath: any; issueStatus: string; formStatus: string; systemStatus: string;}) => ({
    id: result.transactionId,
    scanId: result.transactionId,
    location: result.imageLocation,
    scanDate: result.scanDate,
    issues: parseInt(result.issues, 10),
    formName: '',
    formId: 0,
    formType: result.formType,
    isSubmitted: result.isSubmitted,
    isSetForManualReview: result.isSetForManualReview,
    lastUpdatedDateTime: result.lastUpdatedDateTime,
    lastUpdatedDateTimeSec: result.lastUpdatedDateTimeSec,
    submittedBy: result.submittedBy,
    editedBy: result.editedBy,
    sourceFileObjectPath: result.sourceFileObjectPath,
    issueStatus: result.issueStatus,
    formStatus: result.formStatus,
    systemStatus: result.systemStatus,
})

/**
 * A function to filter scans by unresolvedIssues and scan date
 *
 * @param {Array<SearchScansResponseData>} results
 * @param {*} action
 * @returns {*}
 */
const filterResults = (results: Array<SearchScansResponseData>, action: any) => results
    .filter(result => action.payload.searchParams.unresolvedIssues ? result.isSetForManualReview === true : true)
    .filter(result => dayjs(result.scanDate, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(action.payload.searchParams.scanDateFrom))
    .filter(result => dayjs(result.scanDate, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(action.payload.searchParams.scanDateTo))

/**
 * A slice with reducers of scans store 
 *
 * @type {*}
 */
export const scans = createSlice({
    name: 'scans',
    initialState,
    reducers: {
        resetScansStore: (state) => {
            state = initialState;
            return state;
        },
        resetScansError: (state) => {
            state.status = 'idle';
            state.error = null;
            state.revision = uuidv4();
            return state;
        },
        resetPaginationDetails: (state) => {
            state.paginationDetails = initialState.paginationDetails;
            state.revision = uuidv4();
            return state;
        },
        resetReducerAction: (state) => {
            /**
             * Reducer action is used for FindScans component to better understand what action was successfully completed and what notification to show
             */
            state.reducerAction = '';
            state.revision = uuidv4();
            return state;
        },
        setTotalPageNos: (state, action) => {
            state.totalPages = action.payload;
            state.revision = uuidv4();
            return state;
        },
        setScanPresignedURL: (state, action) => {
            state.scanPresignedURL = action.payload;
            state.revision = uuidv4();
            return state;
        },
        setFullPdfPresignedURL: (state, action) => {
            state.pdfPresignedURL = action.payload;
            state.revision = uuidv4();
            return state;
        },
        setFindScansCurrentPage: (state, action) => {
            state.paginationDetails.findScansCurrentPage = action.payload;
            state.revision = uuidv4();
            return state;
        },
        refilterList: (state, action) => {
            state.list = filterResults(state.unfilteredList, action);
            state.paginationDetails.pages = Math.ceil(state.list.length / state.paginationDetails.itemsPerPage);
            state.revision = uuidv4();
            return state;
        },
        setItemsPerPage: (state, action) => {
            state.paginationDetails.itemsPerPage = action.payload;
            state.paginationDetails.pages = Math.ceil(state.list.length / state.paginationDetails.itemsPerPage);
            state.revision = uuidv4();
            return state;
        },
        updateLastUpdatedDateTime: (state, action) => {
            /**
             * lastUpdatedDateTimeSec is a value important for concurrent editing feature.
             */
            const scanIndex = state.list.findIndex((scan: SearchScansResponseData) => scan.id === action.payload.id)
            if(scanIndex > -1) {
                state.list[scanIndex].lastUpdatedDateTimeSec = action.payload.lastUpdatedDateTimeSec;
            }
            state.revision = uuidv4();
            return state;
        },
        updateAlreadySubmittedScan: (state, action) => {
            const scanIndex = state.list.findIndex((scan: SearchScansResponseData) => scan.id === action.payload.id)
            if(scanIndex > -1) {
                state.list[scanIndex].isSubmitted = true;
                state.list[scanIndex].isSetForManualReview = false;
            }
            state.reducerAction = 'updateAlreadySubmittedScan';
            state.revision = uuidv4();
            return state;            
        },
        resetList: (state) => {
            state.list = [];
            state.paginationDetails.pages = Math.ceil(state.list.length / state.paginationDetails.itemsPerPage);
            return state;
        },
        setSorter: (state, action) => {
            state.sorter = action.payload;
            return state;
        },
        updateScanInList: (state, action) => {
            state.statusAction = 'none';
            let arr: SearchScansResponseData[] = [];
            if (action.payload.selectedFormStatuses.includes(action.payload.formStatus)) {
                arr = state.list.map(item => (item.id === action.payload.id ? { ...item, issues: action.payload.issues, formStatus: action.payload.formStatus, lastUpdatedDateTime: action.payload.lastUpdatedDateTime, lastUpdatedDateTimeSec: action.payload.lastUpdatedDateTimeSec } : item));
            } else {
                arr = state.list.filter(item => item.id !== action.payload.id);
                state.statusAction = 'scanNotInList';
                if (state.list.length > 1) {
                    state.paginationDetails.pages = Math.ceil(state.list.length / state.paginationDetails.itemsPerPage);
                }

            }
            state.list = [...arr as Array<SearchScansResponseData>];
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(searchScans.pending, (state) => {
            state.status = 'loading';
            state.revision = uuidv4();
            state.statusAction = 'searchScans';
            return state;
        })
        .addCase(saveScan.pending, (state) => {
            state.status = 'loading';
            state.statusAction = 'saveScan';
            state.revision = uuidv4();
            return state;
        })
        .addCase(submitScan.pending, (state) => {
            state.status = 'loading';
            state.revision = uuidv4();
            state.statusAction = 'submitScan';
            return state;
        })
        .addCase(searchScans.fulfilled, (state, action) => {
            state.status = 'completed';
            state.statusAction = 'searchScans';
            
            state.list = [...state.list, ...action.payload.response.results
            .map(remapSearchResults) as Array<SearchScansResponseData>];
            // .sort((a, b) => parseInt(dayjs(a.scanDate, 'YYYY-MM-DD HH:mm:ss').format('x'), 10) - parseInt(dayjs(b.scanDate, 'YYYY-MM-DD HH:mm:ss').format('x'), 10))
            // .reverse() as Array<SearchScansResponseData>];
            state.unfilteredList = state.list;
            state.list = filterResults(state.list, action);
            state.currentPageNumber = action.payload.response.currentPageNumber;
            state.lastEvaluatedKey = action.payload.response.lastEvaluatedKey
            state.listByFormType = action.payload.searchParams.selectedTemplateType as unknown as string;
            state.paginationDetails.pages = Math.ceil(state.list.length / state.paginationDetails.itemsPerPage);

            state.revision = uuidv4();
            return state;
        })
        .addCase(saveScan.fulfilled, (state, action) => {
            if(action.payload.status === 202) {
                state.status = 'warning';
                state.error = action.payload.message as string;
            } else if (action.payload.status === 422) {
                state.status = action.payload.status;
                state.error = action.payload.message as string;
                state.errorResults = action.payload.results;
            } else {
                state.status = 'completed';
            }
            state.statusAction = 'saveScan';
            state.revision = uuidv4();
            return state;
        })       
        .addCase(submitScan.fulfilled, (state, action) => {
            if(action.payload.status === 202) {
                state.status = 'warning';
                state.error = action.payload.message as string;
            } else if (action.payload.status === 422) {
                state.status = action.payload.status;
                state.error = action.payload.message as string;
                state.errorResults = action.payload.results;
            } else {
                state.status = 'completed';
            }
            state.statusAction = 'submitScan';
            state.revision = uuidv4();
            return state;
        })   
        .addCase(searchScans.rejected, (state, action) => {
            state.status = 'failed';
            state.statusAction = 'searchScans';
            state.list = [];
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(saveScan.rejected, (state, action) => {
            state.status = 'failed';
            state.statusAction = 'saveScan';
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(submitScan.rejected, (state, action) => {
            state.status = 'failed';
            state.statusAction = 'submitScan';
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
    }
})

export const { resetScansStore, setScanPresignedURL, setFullPdfPresignedURL, resetScansError, resetReducerAction, setFindScansCurrentPage, resetPaginationDetails, refilterList, setItemsPerPage, updateLastUpdatedDateTime, updateAlreadySubmittedScan, setTotalPageNos, resetList, setSorter, updateScanInList } = scans.actions;

export default scans.reducer