/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, List, Radio, Row, Space, Typography } from "antd";
import { SetStateAction, forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LOCALIZATION from '../../../localization';

import colors from "../../../colors";
import './MatchFields.scss';
import { CustomListItemProps, Item, Pair } from "../types";
import StyledSpaceWithTopMargin from "../../../components/styled/StyledSpaceWithTopMargin";
import { RootState } from "../../../store/store";
import AccessDeniedContext from "../../../context/AccessDenied";
 
const { Text } = Typography;

interface MatchFieldsProps {
  /** Set if pair is editable */
  editable?: boolean;
  /** A list of mapped pairs */
  mapping: Pair[];
  /** A list of template fields */
  templateFields: Item[];
  /** A list of schema fields */
  schemaFields?: Item[];
  /** A reference to getter and setter for pairs */
  matchFields: { getPairs: () => Pair[]; setPairs: (values: SetStateAction<Pair[]>) => void; };
  /** A reference to method that handles select of field on the right side */
  onRightSelected?: (item: Item) => void;
  /** A reference to method that handles select of fields pair */
  onPairSelected?: (item: Pair) => void;
  /** A reference to method that handles click on match fields button */
  onMatch?: (list: Pair[]) => void;
  /** A reference to method that handles click on umatch */
  onUnmatch?: () => void;
}

/**
 * Left and right column header.
 */
export const ListHeader = styled(List.Item)`
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.1) !important; //xxx
  margin-top: 8px;
  margin-bottom: 8px;
`

/**
 * Item in the left and right column with different styles if is checked or matched.
 */
const CustomListItem = styled(List.Item)<CustomListItemProps>`
  background-color: ${props => props.checked ? colors.bgButtonIdle : (props.matched === "true") ? 'rgba(255, 255, 255, 0.35)' : 'none' } !important;
  & > span {
    color: ${props => props.checked ? `${colors.textButtonHover} !important` : 'none'}
  }
`

/**
 * Button to confirm match of the items.
 */
export const MatchButton = styled(Button).attrs((props) => ({
  ...props,
  size: 'middle'
}))`
  background-color: rgba(255,255,255,0) !important;
  display: ${props => props.hidden ? 'none' : ''};
  border-radius: 3px;
  border: 2px solid ${props => props.disabled ? 'var(--color-accent-03)' : 'var(--color-white)'};
  width: 100%;
  & span {
    font-family: Interstate;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.disabled ? 'var(--color-accent-04)' : 'var(--color-white)'};
    vertical-align: text-top;
  }
  &:hover {
    color: ${colors.bgButtonIdle} !important;
    border-color: ${colors.bgButtonIdle} !important;
    & span {
      color: ${colors.bgButtonIdle};
    }
  }
  &:active {
    background-color: var(--color-neutral-01) !important;
  }
}
`

/**
 * Icon between two matched items in a normal editable and not selected state.
 */
const MatchIcon = styled('div')`
  background: url(/match.svg) no-repeat center / contain;
  width: 28px;
  height: 22px;
  position: relative;
  right: -39px;
  float: right;
  z-index: 1;
`

/**
 * Icon between two matched items in a normal editable and selected state.
 */
const UnmatchIcon = styled('div')`
  background: url(/unmatchFields.svg) no-repeat center / contain;
  width: 28px;
  height: 22px;
  position: relative;
  right: -39px;
  float: right;
  z-index: 1;
`

/**
 * Icon between two matched items in a read-only state.
 */
const DisabledUnmatchIcon = styled('div')`
  background: url(/match_inactive.svg) no-repeat center / contain;
  width: 28px;
  height: 22px;
  position: relative;
  right: -39px;
  float: right;
  z-index: 1;
`

/**
 * Text inside an item.
 */
export const ItemText = styled(Text)`
  color: ${props => props.disabled  ? colors.colorNeutral : ''};
`

/**
 * MatchFields component provides feature to match fields from SAP schema with fields from form template.
 * 
 * This component is using props to exchange data and actions with parent component.
 *
 * @param {MatchFieldsProps} {
  editable,
  templateFields,
  schemaFields,
  mapping,
  matchFields,
  onRightSelected,
  onPairSelected,
  onMatch,
  onUnmatch
}
 * @returns
 */
const MatchFields = forwardRef<HTMLDivElement, MatchFieldsProps>(({
  editable,
  templateFields,
  schemaFields,
  mapping,
  matchFields,
  onRightSelected,
  onPairSelected,
  onMatch,
  onUnmatch
}, ref) => {

    const leftInitData: Item[] = mapping.map(item => ({
      value: item.left || '',
      paired: !!item.right
    }));

    let order = 0;

    const [leftData, setLeftData] = useState<Item[]>((editable && schemaFields) ? schemaFields : leftInitData);

    const [middleData, setMiddleData] = useState<Pair[]>(editable ? [] : mapping);

    const [rightData, setRightData] = useState<Item[]>(templateFields.map(item => {
      item.order = order++;
      return item;
    }).sort((a, b) => Number(a.order) - Number(b.order)));

    const [leftSelected, setLeftSelected] = useState<Item|undefined>(undefined);
    const [rightSelected, setRightSelected] = useState<Item|undefined>(undefined);
    const [middleSelected, setMiddleSelected] = useState<Pair>();

    const [enabledMatchButton, setEnabledMatchButton] = useState<boolean>(false);
    const [enabledUnmatchButton, setEnabledUnmatchButton] = useState<boolean>(false);
    const [enabledRightList, setEnabledRightList] = useState<boolean>(false);
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
    const { isAccessDenied } = useContext(AccessDeniedContext);
    const outputsStoreSchema = useSelector((state: RootState) => state.outputs.schema);

    useEffect(() => {
      if (editable) {
        setLeftData(outputsStoreSchema ? outputsStoreSchema.map(item => ({
          value: item,
          paired: false
        })) : leftInitData);
        setMiddleData([]);
        setRightData(templateFields.map(item => {
          item.order = order++;
          return item;
        }).sort((a, b) => Number(a.order) - Number(b.order)));
        setMiddleSelected(undefined);
        setRightSelected(undefined);
        setLeftSelected(undefined);
      }
    }, [outputsStoreSchema]);

    /**
     * Update matched fields in hook after the middle column changed.
    */
    useEffect(() => {
      matchFields.setPairs(middleData);
    }, [middleData]);

    /**
     * Runs 'onPairSelected' event function from props when already paired item was selected.
     */
    useEffect(() => {
      if(onPairSelected){
        onPairSelected({
          rightId: middleSelected?.rightId,
          left: middleSelected?.left,
          right: middleSelected?.right
        });
      }
    }, [middleSelected]);

    /**
     * Pairs items based on what is stored in the state and reset the new states for left, middle and right list.
     */
    const pairValues = () => {
      const newMiddleData = [...middleData, {
        rightId: rightSelected?.id,
        rightOrder: rightSelected?.order,
        left: leftSelected?.value,
        right: rightSelected?.value
      }];

      setMiddleData(newMiddleData)

      const newLeftData: Item[] = leftData.map((leftItem: Item) => {
        if(leftItem.value === leftSelected?.value) {
          return {
            value: leftSelected?.value,
            paired: true
          }
        }
        return leftItem;
      })

      const newRightData: Item[] = rightData.map((rightItem: Item) => {
        if(rightItem.id === rightSelected?.id && rightItem.value === rightSelected?.value) {
          return {
            value: rightSelected?.value,
            paired: true
          }
        }
        return rightItem;
      })

      setLeftData(newLeftData.sort((a, b) => Number(b.paired) - Number(a.paired)));
      setRightData(newRightData.filter((item => !item.paired)).sort((a, b) => Number(a.order) - Number(b.order)));

      setRightSelected(undefined);
      setLeftSelected(undefined);
      setEnabledMatchButton(false);
      setEnabledRightList(false);

      if(onMatch){
        onMatch(newMiddleData);
      }
    }

    /**
     * Unpairs matched items based on what is stored in the state and reset the new states for left, middle and right list.
     */
    const unpairValues = () => {
      setMiddleData(middleData.filter((item => item.left !== middleSelected?.left && (item.rightId !== middleSelected?.rightId || item.right !== middleSelected?.right))))// todo not same values on both of sides

      const newLeftData = leftData.map(leftItem => {
        if(middleSelected && leftItem.value === middleSelected.left) {
          return {
            value: leftItem.value,
            paired: false
          }
        }
        return leftItem;
      })

      rightData.push({
        id: middleSelected?.rightId,
        order: middleSelected?.rightOrder,
        value: middleSelected ? middleSelected.right : undefined,
        paired: false
      })

      setLeftData(newLeftData.sort((a, b) => Number(b.paired) - Number(a.paired)));
      setRightData(rightData.filter((item => !item.paired)).sort((a, b) => Number(a.order) - Number(b.order)));

      setMiddleSelected(undefined);
      setRightSelected(undefined);
      setLeftSelected(undefined);
      setEnabledMatchButton(false);
      setEnabledRightList(false);

      if(onUnmatch){
        onUnmatch();
      }
    }

    /**
     * Compares item value with a relevant selection state and returns if the item is checked or not.
     * 
     * @param value string | undefined String value or ID of field
     * @param selected string | undefined String value or ID of field
     * @returns boolean 
     */
    const isChecked = (value: string|undefined, selected: Pair|string|undefined) => {
      if (!editable || (!value || !selected) ) {
        return false;
      }
      return value === selected
    };

    /**
     * Returns "Match" icon displayed between paired items based on selection or state.
     * 
     * @param item Item Paired list item
     * @returns Icon component
     */
    const getIcon = (item: Item) => {
      if (isChecked(item.value, middleSelected?.left)) {
        return <UnmatchIcon/>
      }
      if (editable) {
        return <MatchIcon/>
      }
      return <DisabledUnmatchIcon/>
    }

    return (
      <Col span={24} className="match-fields-base" style={{height: '100%'}}>
        <Row>
          {editable ?
            <>
              <Text className="match-fields-title">{LOCALIZATION.MATCH_FIELDS_TITLE}</Text>
              <Text className="flow-tasks-description">{LOCALIZATION.MATCH_FIELDS_DESCRIPTION}</Text>
            </>
            :
            <Text className="match-fields-title">{LOCALIZATION.MATCH_FIELDS_TITLE_READONLY}</Text>
          }
        </Row>
        <Row>
          <Col span={editable ? 8 : 12}>
            <ListHeader className="match-fields-list-header match-fields-list-header-left">{LOCALIZATION.MATCH_FIELDS_LEFT_COLUMN_TITLE}</ListHeader>
          </Col>
          {editable ?
            <Col span={8} className="match-fields-buttons-wrapper">
              <Row>
                <Col span={4}/>
                <Col span={16}>
                  <StyledSpaceWithTopMargin className="match-fields-styled-space">
                    {!middleSelected ? 
                      <MatchButton disabled={!enabledMatchButton} onClick={() => {
                        pairValues();
                      }}>
                        {LOCALIZATION.MATCH_FIELDS_MATCH_BUTTON}
                      </MatchButton> : 
                      <MatchButton disabled={!enabledUnmatchButton} onClick={() => {
                        unpairValues();
                      }}>
                        {LOCALIZATION.MATCH_FIELDS_UNMATCH_BUTTON}
                      </MatchButton>
                    }
                  </StyledSpaceWithTopMargin>
                </Col>
                <Col span={4}/>
              </Row>
            </Col> : null }
            <Col span={editable ? 8 : 12}>
              <ListHeader className="match-fields-list-header match-fields-list-header-right">{LOCALIZATION.MATCH_FIELDS_RIGHT_COLUMN_TITLE}</ListHeader>
            </Col>
        </Row>
        <Row className={`${editable ? "match-fields-columns-editable" : "match-fields-columns"} ${(!isAdmin || isAccessDenied) && "match-fields-view-columns"}`}>
          <Col span={editable ? 16 : 24} className="match-fields-list-height" ref={ref}>
            <Row>
              <Col span={editable ? 12 : 12}>    
                <List
                  bordered
                  dataSource={leftData}
                  className="custom-list"
                  renderItem={(item) => {
                    if(item.paired){
                      return <CustomListItem id={item.value} onClick={() => {
                          if(!item.paired){
                            return;
                          }
                          const selectedPair = middleData.filter(pair => pair.left === item.value)[0];
                          setLeftSelected(undefined);
                          setMiddleSelected(selectedPair);
                          setEnabledUnmatchButton(true);
                        }} matched={item.paired.toString()} checked={isChecked(item.value, middleSelected?.left)}>
                        <Text className="list-text">{item.value}</Text>
                        { getIcon(item) }
                      </CustomListItem>
                    }
                    return <CustomListItem checked={isChecked(item.value, leftSelected?.value)} onClick={() => {
                        setMiddleSelected(undefined);
                        setLeftSelected({
                          id: item.id,
                          value: item.value,
                          paired: item.paired
                        });
                        setEnabledRightList(true);
                      }}>
                      <Text className="list-text">{item.value}</Text>
                      <Radio id={`${item.value}-left`} name={item.value} className="match-fields-radio match-fields-left-list-radio" checked={isChecked(item.value, leftSelected?.value)} onChange={() => {
                        setMiddleSelected(undefined);
                        setLeftSelected({
                          id: item.id,
                          value: item.value,
                          paired: item.paired
                        });
                        setEnabledRightList(true);
                      }}/>
                    </CustomListItem>
                  }}
                />    
              </Col>
              <Col span={editable ? 11 : 12}>
                <List
                  bordered
                  dataSource={middleData}
                  className="custom-list middle-custom-list"
                  renderItem={(item) => (
                    <CustomListItem checked={isChecked(item.left, middleSelected?.left)} matched="true" onClick={() => {
                        setLeftSelected(undefined)
                        setMiddleSelected(item);
                        setEnabledUnmatchButton(true);
                      }}>
                      <Text className="list-text">{item.right}</Text>
                    </CustomListItem>
                  )}
                />
              </Col>
            </Row>
          </Col>
          { editable ? <Col span={8} className='match-fields-list-height'>  
            <List
              bordered
              dataSource={rightData}
              className="custom-list"
              renderItem={(item) => (
                <CustomListItem id={`${item.value}-right`} className={!enabledRightList ? "custom-list-item-disabled" : ""} disabled={!enabledRightList} checked={isChecked(item.id || item.value, rightSelected?.id || rightSelected?.value)} onClick={(e) => {
                    if(!enabledRightList){
                      e.stopPropagation();
                      e.preventDefault();
                    }
                    else {
                      setRightSelected({
                        id: item.id,
                        order: item.order,
                        value: item.value,
                        paired: item.paired
                      });
                      if(onRightSelected){
                        onRightSelected(item);
                      }
                      setEnabledMatchButton(true);
                    }
                  }}>
                  <Radio id={`${item.value}-right`} className="match-fields-radio" name={item.value} checked={isChecked(item.id || item.value, rightSelected?.id || rightSelected?.value)} disabled={!enabledRightList} onChange={() => {
                    setRightSelected({
                      id: item.id,
                      order: item.order,
                      value: item.value,
                      paired: item.paired
                    });
                    if(onRightSelected){
                      onRightSelected(item);
                    }
                    setEnabledMatchButton(true);
                  }}/>
                  <ItemText disabled={!enabledRightList} className="list-text">{item.value}</ItemText>
                </CustomListItem>
              )}
            />    
          </Col> : null }
        </Row>
      </Col>
    )
})

MatchFields.defaultProps = {
  editable: false,
  onRightSelected: () => {},
  onMatch: () => {},
  schemaFields: [],
  onPairSelected: () => {},
  onUnmatch: () => {}
}

export default MatchFields;
