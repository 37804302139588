import { createContext } from "react";

/**
 * A default values for context.
 * isAccessDenied is false, because by default access is allowed
 * triggerAccessDeniedModal is by default empty function for TypeScript's sake
 *
 * @type {{ isAccessDenied: boolean; triggerAccessDeniedModal: () => void; }}
 */
const ACCESS_DENIED_DEFAULT = {
  isAccessDenied: false,
  triggerAccessDeniedModal: () => {}
}

const AccessDeniedContext = createContext(ACCESS_DENIED_DEFAULT);
AccessDeniedContext.displayName = 'AccessDenied';

export default AccessDeniedContext;