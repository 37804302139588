import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * An interface with keys for state of users store
 *
 * @export
 * @interface UserState
 * @typedef {UserState}
 */
export interface UserState {
  email: string;
  roles: Array<string>;
  identities: string;
  isAdmin: boolean;
}

/**
 * An initial state of users store
 *
 * @type {UserState}
 */
const initialState: UserState = {
  email: '',
  roles: [''],
  identities: '',
  isAdmin: false
};

/**
 * A slice with reducers of users store
 *
 * @type {*}
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserState>) => ({ ...state, ...action.payload })
  }
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;