/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Space, Col, Row, Select, Form, Typography, Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './AdhocScan.scss';
import { v4 as uuid } from 'uuid';
import StyledCollapse from '../../components/styled/StyledCollapse';
import HeaderPanel from '../../components/styled/HeaderPanel';
import BoundingBoxOverlay from '../../components/ImageAnnotation';
import { Record } from '../../interfaces/Record';
import { AppDispatch, RootState } from '../../store/store';
import { SearchScansParams } from '../../api/scansApi.service';
import { searchScans } from '../../store/scans/scansReducer';
import { fetchForms } from '../../store/forms/formsReducer';
import FullWidthSpace from '../../components/styled/FullWidthSpace';
import HeaderRow from '../../components/HeaderRow';

import data from './data.json';

const FREE_TEXT = 'str';

const LOCALIZATION = {
  FORM_TO_SCAN_TITLE: 'Form to scan',
  SELECT_FORM_LABEL: 'Select Form',
  SELECT_FILE_LABEL: 'Select File',
  SCAN_FIELD_LABEL: 'Scan Field (optional)',
  FORM_NAME_TITLE: 'Form (Form Name)',
  SCAN_NUM_TITLE: 'Scan: 001',
  SELECT_SCAN_DATE_RANGE_LABEL: 'Select Scan Date Range',
  SELECT_SCAN_DATE_START_PLACEHOLDER: 'Start Date',
  SELECT_SCAN_DATE_END_PLACEHOLDER: 'End Date',
  SEARCH_VALUE_LABEL: 'Search Value (optional)',
  SEARCH_VALUE_PLACEHOLDER: 'Search Value',
  UNRESOLVED_ISSUES_LABEL: 'Unresolved issues: ',
  SCAN_FILE_BUTTON_LABEL: 'Scan File',
  SUBMIT_SCAN_BUTTON_LABEL: 'Submit Scan',
  CLEAR_SEARCH_BUTTON_LABEL: 'Reset Search',
  FIND_SCANS_RESULTS__HEADER_SCAN_ID: 'ScanID',
  FIND_SCANS_RESULTS__HEADER_ORIG_LOCATION: 'Original Location',
  FIND_SCANS_RESULTS__HEADER_SCAN_DATE: 'Scan Date',
  FIND_SCANS_RESULTS__HEADER_ISSUES: 'Issues',
  FORM_FIELDS_TABLE__HEADER_KEY: 'Key',
  FORM_FIELDS_TABLE__HEADER_SCANNED_VALUE: 'Scanned Value',
  FORM_FIELDS_TABLE__HEADER_ACCEPTED_VALUE: 'Accepted Value',
  FORM_FIELDS_TABLE__HEADER_MATCH: 'Match',
  FORM_FIELDS_TABLE__HEADER_CONFIDENCE: 'Confidence',
  FORM_FIELDS_TABLE__HEADER_LOCK: ''
}

const FIND_SCANS_FORM_NAMES = {
  SELECT_FORM: 'selectForm',
  SELECT_FILE: 'selectFile',
  SCAN_FIELD: 'scanField',
  SCAN_DATE_START: 'scanDateStart',
  SCAN_DATE_END: 'scanDateEnd',
  SEARCH_VALUE: 'searchValue',
  UNRESOLVED_ISSUES: 'unresolvedIssues'
}

interface SelectOptions {
  label: string;
  value: string;
}

const { Text } = Typography;

function AdhocScan() {
  const [findScansForm] = Form.useForm<SearchScansParams>();
  const [fields, setFields] = useState<Record[]>([]);
  const [document, setDocument] = useState<boolean>(false);
  const [current, setCurrent] = useState({});
  const [formsOptions, setFormsOptions] = useState<SelectOptions[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const scansStoreList = useSelector((state: RootState) => state.scans.list);
  const scansStoreStatus = useSelector((state: RootState) => state.scans.status);

  const formsStoreList = useSelector((state: RootState) => state.forms.list);
  const formsStoreStatus = useSelector((state: RootState) => state.forms.status);

  useEffect(() => {
    setFormsOptions(() => formsStoreList.map(item => ({ value: item.id, label: item.formName})));
  }, [formsStoreList])

  useEffect(() => {
    if(formsStoreList.length === 0) {
      dispatch(fetchForms());
    }
  }, []);

  const handleSearchScansFormFinish = (values: any) => {
    dispatch(searchScans(values));
  }

  const handleClearSearchClick = () => {
    findScansForm.resetFields();
    dispatch(searchScans({}));
  }
  
  const round = (num: number | undefined, decimalPlaces: number = 2) => {
    if (!num) return 0;

    let multiplication = 1;
    for (let dp = 0; dp < decimalPlaces; dp += 1) {
      multiplication *= 10;
    }
    return Math.round((num + Number.EPSILON) * multiplication) / multiplication;
  };

  const scanFile = () => {
    const newFields: Record[] = [];

    data.filter(item => newFields.push({
        id: uuid(),
        name: item.key,
        type: FREE_TEXT,
        confidence: item.confidence,
        match: item.match,
        scannedValue: item.scanned_value,
        acceptedValue: item.accepted_value,
        locked: false,
        geometry: {
          height: 0,
          width: 0,
          type: 'RECTANGLE',
          x: 0,
          y: 0
        }
    }))
    setDocument(true);
    setFields(newFields);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <StyledCollapse collapsible="icon" defaultActiveKey={["1"]}>
            <HeaderPanel header={LOCALIZATION.FORM_TO_SCAN_TITLE} key="1">
              <Form 
                form={findScansForm}
                name="search-scans-form" 
                onFinish={handleSearchScansFormFinish}
                layout="vertical"
              >
                <Row>
                  <Col span={10}>
                    <FullWidthSpace>
                      <Form.Item label={LOCALIZATION.SELECT_FORM_LABEL} name={FIND_SCANS_FORM_NAMES.SELECT_FORM}>
                        <Select 
                          placeholder={LOCALIZATION.SELECT_FORM_LABEL}
                          options={formsOptions}
                          loading={formsStoreStatus === 'loading'}
                          virtual={false}
                        />
                      </Form.Item>
                    </FullWidthSpace>
                  </Col>
                  <Col span={10}>
                    <FullWidthSpace>
                      <Form.Item label={LOCALIZATION.SELECT_FILE_LABEL} name={FIND_SCANS_FORM_NAMES.SELECT_FILE}>
                        <Select 
                          placeholder={LOCALIZATION.SELECT_FILE_LABEL}
                          options={formsOptions}
                          loading={formsStoreStatus === 'loading'}
                          virtual={false}
                        />
                      </Form.Item>
                    </FullWidthSpace>
                  </Col>
                  <Col span={2}/>
                  <Col span={2}>
                    <Space>
                      <Form.Item label="&nbsp;" name={FIND_SCANS_FORM_NAMES.UNRESOLVED_ISSUES}>
                        <Button type="primary" onClick={scanFile} htmlType="submit">{LOCALIZATION.SCAN_FILE_BUTTON_LABEL}</Button>
                      </Form.Item>
                    </Space>                                        
                  </Col>
                </Row>
              </Form>
            </HeaderPanel>
          </StyledCollapse>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledCollapse collapsible="icon" defaultActiveKey={["1"]} className='xxxx'>
            <HeaderRow leftSideText={LOCALIZATION.SCAN_NUM_TITLE} rightSideText={LOCALIZATION.FORM_NAME_TITLE}>
              <Col span={12}>
                  <Table
                    columns={[{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_KEY,
                      dataIndex: 'name',
                      key: 'id'
                    },{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_SCANNED_VALUE,
                      dataIndex: 'scannedValue',
                      key: 'scanned_value'
                    },{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_ACCEPTED_VALUE,
                      dataIndex: 'acceptedValue',
                      key: 'accepted_value'
                    },{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_MATCH,
                      dataIndex: 'match',
                      key: 'match'
                    },{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_CONFIDENCE,
                      dataIndex: 'confidence',
                      key: 'confidence'
                    },{
                      title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_LOCK,
                      dataIndex: 'locked',
                      key: 'locked'
                    }]}
                    dataSource={fields}
                  />
                  <Row>
                    <Col offset={6} span={12}>
                      <Button type="primary" style={{display: "inline-flex", alignItems: "center"}} htmlType="submit">{LOCALIZATION.SUBMIT_SCAN_BUTTON_LABEL}</Button>
                    </Col>
                  </Row>  
              </Col>
              <Col span={12}>
                { document &&
                  <BoundingBoxOverlay
                    src="/documentExample1.jpg"
                    alt="alt"
                    annotations={fields}
                    value={current}
                    // onChange={onChange}
                    // onSubmit={onSubmit}
                    // onCancel={onCancel}
                    // onDelete={handleDelete}
                  />
                }
              </Col>
            </HeaderRow>
          </StyledCollapse>
        </Col>
      </Row>
    </>
  );
}

export default AdhocScan;