import { Collapse } from "antd";
import styled from "styled-components";
import colors from "../../colors";

const { Panel } = Collapse;

/**
 * A styled Collapse.Panel component with changed background color.
 *  
 * @component 
 * 
 */
export default styled(Panel)`
    background-color: ${colors.bgHeaderCol};

    & .ant-collapse-header-text {
        font-size: 18px;
    }
`
