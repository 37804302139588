import axios from "axios";
import { v4 as uuid } from 'uuid';
import { ApiResponse } from "../interfaces/ApiResponse";

/**
 * An interface describing mapped flow tasks data in response
 * @interface
 */
export interface FetchFlowTasksResponseData {
    "flowName": string;
    "formType": string;
    "formTemplateName": string;
    "status": string;
    "updatedAt": string;
}

/**
 * An interface describing flow tasks' name-type pair
 * @interface 
 */
export interface FlowTaskByNameParams {
    formName: string;
    formType: string;
}

/**
 * An interface describing general data of specific flow task
 * @interface
 */
export interface FlowTask {
    id?: string | undefined;
    formName: string;
    formType: string;
    status?: string | undefined;
    lastUpdate?: string | undefined;
    createdAt?: string | undefined;
    flowName?: string | undefined;
    outputLocation?: string | undefined;
    outputSchema?: string | undefined;
    imagePresignedUrl?: string | undefined;
    mapping?: any; // todo
    schemaFields?: string[],
    fields?: Field[],
    allForManualReview?: boolean | undefined,
    lastUsedDateTime?: number
}

/**
 * An interface describing single scanned field
 * @interface
 */
export interface Field {
    geometry: {
        height: number;
        width: number;
        x: number;
        y: number;
        type: string;
    }
    id: string;
    confidence: number;
    fieldName: string;
    isRequired: boolean;
    message: string;
    requiredType: string;
    pageY?: any;
}

/**
 * An API service for retrieving and updating flow tasks
 * 
 * @module api/flowTasksApi.service
 */
export const flowTasksApi = {
    /**
     * Fetch a flow task by an form type/form template pair
     * 
     * @param {string} formName Name of form template
     * @param {string} formType Name of form type
     * @returns {Promise<ApiResponse>} A general API response
     */
    getById: async (formName: string, formType: string): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/flowtasks/byName/${formType}/${formName}`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const alteredResponseData = { ...response.data};
            alteredResponseData.results = alteredResponseData.results.map((result: any): FlowTask => (
                {
                    id: uuid(),
                    formName: result.templateName,
                    formType: result.formType,
                    status: result.status,
                    lastUpdate: result.lastUpdate,
                    createdAt: result.createdAt,
                    flowName: result.flowName,
                    outputLocation: result.outputLocation,
                    outputSchema: result.outputSchema,
                    mapping: result.mapping,
                    schemaFields: result.schemaFields,
                    fields: result.fields.map((field: any) => ({
                        geometry: {
                            height: field.boundingBox.height,
                            width: field.boundingBox.width,
                            x: field.boundingBox.x,
                            y: field.boundingBox.y,
                            type: 'RECTANGLE'
                        },
                        id: field.id,
                        confidence: field.confidence,
                        fieldName: field.fieldName,
                        name: field.fieldName,
                        isRequired: field.isRequired,
                        message: field.message,
                        requiredType: field.requiredType,
                        pageY: field.pageY,
                    })),
                    imagePresignedUrl: result.imagePresignedUrl,
                    allForManualReview: result.allForManualReview,
                    lastUsedDateTime: parseFloat(result.lastUsedDateTime)
                }
            ));
            return alteredResponseData;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Fetch a flow task by an form type/form template pair
     * 
     * @param {string} formName Name of form template
     * @param {string} formType Name of form type
     * @param {Object} payload An object with data to change or to create a new flow task from
     * @returns {Promise<ApiResponse>} A general API response
     */    
    putFlowTaskByName: async (formName: string, formType: string, payload: any): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/flowtasks/byName/${formType}/${formName}`;
            const response = await axios.put(path, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    }
}