/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from '../../interfaces/ApiStatus';
import { TemplateTypeOutput, TemplatesAllOutput } from '../../api/formsApi.service';
import { fetchForms } from '../forms/formsReducer';

/**
 * An interface describing state of formTypes store
 *
 * @interface FormTypeState
 * @typedef {FormTypeState}
 */
interface FormTypeState {
    list: Array<TemplateTypeOutput>,
    status: ApiStatus,
    error: string | null
}

/**
 * An initial state of formTypes store
 *
 * @type {FormTypeState}
 */
const initialState: FormTypeState = {
    list: [],
    status: 'idle',
    error: null
}

/**
 * A slice with reducers of formTypes store
 *
 * @type {*}
 */
export const formTypes = createSlice({
    name: 'formTypes',
    initialState,
    reducers: {
        addNewType: (state, action) => {
            state.list.push({
                type: action.payload
            });
        },
        resetFormTypesError: (state) => {
            state.error = null;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchForms.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchForms.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list = action.payload.results
                .reduce((prevValue, currentValue: TemplatesAllOutput) => prevValue.indexOf(currentValue.type) === -1 ? [...prevValue, currentValue.type] : prevValue, [])
                .map((value: string) => ({ type: value})) as TemplateTypeOutput[];
            return state;
        })
        .addCase(fetchForms.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            return state;
        })
    }
})

export const { resetFormTypesError, addNewType } = formTypes.actions;

export default formTypes.reducer