/* eslint-disable react/jsx-no-useless-fragment */
import React from "react"
import { Button } from "antd";
import styled from "styled-components";
import { Record } from '../../../interfaces/Record';

/**
 * A styled UL element
 *
 * @type {*}
 */
const StyledUl = styled.ul`
    animation-name: fadeIn;
    animation-duration: 0.4s;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0px;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 0px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    & li {
        clear: both;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        margin: 0;
        padding: 5px 12px;
        transition: all .3s;
        white-space: nowrap;
        -webkit-transition: all .3s;
        background: var(--color-primary-02);
    }

    // & li:hover {
    //     background-color: #e6f7ff;
    // }

    & li > i {
        margin-right: 8px;
    }
`

/**
 * An interface for props of this component
 *
 * @interface RowContextMenuProps
 * @typedef {RowContextMenuProps}
 */
interface RowContextMenuProps {
    /**
     * A reference to selected record
     * @type {Record}
     */
    record: Record;
    /**
     * A reference to method in parent component, that will handle delete of selected record
     * @type {(record: Record) => void}
     */
    onDelete: (record: Record) => void;
    /**
     * A switch to decide if context menu should be visible or not
     * @type {boolean}
     */
    visible: boolean;
    /**
     * A X coordinate to place where context menu should be displayed
     * @type {number}
     */
    x: number;
    /**
     * A Y coordinate to place where context menu should be displayed
     * @type {number}
     */
    y: number;
  }

/**
 * RowContextMenu is a component that is displayed after right-click on specific row in fields table.
 *
 * @param {RowContextMenuProps} {record, onDelete, visible, x, y}
 * @returns {*}
 */
function RowContextMenu({record, onDelete, visible, x, y}: RowContextMenuProps) {
  return <>
  {visible &&
    <StyledUl className="popup" style={{left: `${x}px`, top: `${y}px`}}>
        <li>
            <Button 
                size="middle" 
                type="text" 
                onClick={() => onDelete(record)}
            >
                Delete Record
            </Button>
        </li>
    </StyledUl>
  }
  </>
}

export default RowContextMenu;
