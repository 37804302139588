import React from 'react';
import styled from 'styled-components';

interface PageLoaderProps {
  spinning: boolean;
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;  
  right: 0;
  bottom: 0;
  z-index: 9999;
`;

const PageLoader: React.FC<PageLoaderProps> = ({ spinning }) => (
    spinning ? (
      <LoaderContainer/>
    ) : null
  );


/** @component */
export default PageLoader;
