import { Button, Dropdown, MenuProps, Space } from "antd";
import dayjs from "dayjs";
import { ExportOutlined } from "@ant-design/icons";
import LOCALIZATION from '../../../localization';

interface ReportDropdownProps {
    onItemClick: (values: any) => void;
}

function ReportDropdown({
    onItemClick
}: ReportDropdownProps) {
    const items: MenuProps['items'] = [{
        key: `${dayjs().hour(0).minute(0).second(0).subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')};${dayjs().hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')};30`,
        label: LOCALIZATION.FIND_SCANS_GENERATE_REPORT_30DAYS
    },{
        key: `${dayjs().hour(0).minute(0).second(0).subtract(60, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')};${dayjs().hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')};60`,
        label: LOCALIZATION.FIND_SCANS_GENERATE_REPORT_60DAYS
    }]

    const menuProps = {
        items,
        onClick: (e: any) => {
            const keySliced = e.key.split(';')
            if(keySliced.length === 3) {
                onItemClick({
                    scanDateFrom: keySliced[0],
                    scanDateTo: keySliced[1],
                    period: keySliced[2]
                })
            }
        }
    }

    return (
        <Space>
            <Dropdown menu={menuProps}>
                <Button size="middle"><ExportOutlined />{LOCALIZATION.FIND_SCANS_GENERATE_REPORT_DROPDOWN}</Button>
            </Dropdown>
        </Space>
    )
}

export default ReportDropdown;
