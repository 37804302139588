import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/find-scans');
  };
  
  // to do login preparation
  return (
    <div>
      <Button type="primary" onClick={handleLogin}>
        Login
      </Button>
    </div>
  );
}

export default Login;