import { Space } from "antd";
import styled from "styled-components";

/**
 * A styled Space component with width set to 100% and right padding to 30px.
 *  
 * @component 
 * */
export default styled(Space)`
width: 100%;

& .ant-space-item {
  padding-right: 30px;
  width: 100%;
}
`