import { Row } from "antd";
import styled from "styled-components";

export default styled(Row)`
    background-color: var(--color-primary-01);
    color: var(--color-white);
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
`
